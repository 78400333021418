import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-element-test',
  templateUrl: './element-test.component.html',
  styleUrl: './element-test.component.css'
})
export class ElementTestComponent implements OnInit {
  createEventForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.createEventForm = this.fb.group({
      CountryName: ['', Validators.required],
      StateProvinceRegion: ['', Validators.required],
      CityTown: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.createEventForm.valid) {
      console.log('Form Submitted!', this.createEventForm.value);
    } else {
      this.createEventForm.markAllAsTouched(); // Ensure all fields are validated
    }
  }

  ngOnInit(): void {
    
  }

}
