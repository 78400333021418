import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page, EventListItem, EventCardItem  } from '../models/event';
import { Account } from '../models/account';
import { environment } from '../../environments/environment';


@Injectable({providedIn: 'root'})

export class BackendService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }


  getEventList(pageNo: number = 1, country:string, type:string, source:string): Observable<Page<EventListItem>> 
  {
      const url = `${this.apiUrl}events/getall?view=list&page=${pageNo}&country=${country}&type=${type}&source=${source}`;
      return this.http.get<Page<EventListItem>>(url);
  }

  getEventCards(pageNo: number = 1, country:string, province:string, type:string): Observable<Page<EventCardItem>> 
  {
      const url = `${this.apiUrl}events/getall?view=list&page=${pageNo}&country=${country}&province=${province}`;
      return this.http.get<Page<EventCardItem>>(url);
  }


  addEvent()
  {
      var newEvent = {  id: '1', title: 'Test Event', description: 'This is a test event', coverImgURL: 'https://via.placeholder.com/150', city: 'Helsinki', province: 'Uusimaa' };

      this.http
      .post(this.apiUrl + 'events/add', newEvent)
      .subscribe(apiResponse => 
      {
        console.log(apiResponse);
      });
  }



  // Account Service:
  getAccounts(): Observable<Account[]>
  {
      return this.http.get<Account[]>(this.apiUrl + 'accounts/getall');
  }

}
