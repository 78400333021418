<form [formGroup]="createEventForm" (ngSubmit)="createEvent()" class="container mt-5 p-4 bg-white shadow rounded">
  <h2 class="text-center text-dodgerblue mb-4">Create Event</h2>
  
  <div class="row mb-3">
    <div class="col-md-6">
      <label for="title" class="form-label">Title</label>
      <input id="title" formControlName="Title" class="form-control" placeholder="Enter event title" />
      <span *ngIf="createEventForm.get('Title')?.invalid && createEventForm.get('Title')?.touched" class="text-danger">Title is required</span>
    </div>
    <div class="col-md-6">
      <label for="type" class="form-label">Event Type</label>
      <select id="type" formControlName="Type" class="form-select">
        <option *ngFor="let type of eventTypes" [value]="type">{{ type }}</option>
      </select>
      <span *ngIf="createEventForm.get('Type')?.invalid && createEventForm.get('Type')?.touched" class="text-danger">Event Type is required</span>
    </div>
  </div>
  
  <div class="row mb-3">
    <div class="col-md-6">
      <label for="description" class="form-label">Description</label>
      <textarea id="description" formControlName="Description" rows="5" class="form-control" placeholder="Describe the event">games on the beach</textarea>
    </div>

    <div class="col-md-6">
      <label for="cover-image" class="form-label">Cover Image</label>
      <input id="cover-image" type="file" class="form-control" (change)="onCoverImageSelected($event)" accept="image/*" />
      <div *ngIf="coverImageUrl" class="mt-2">
        <img [src]="coverImageUrl" alt="Cover Image Preview" class="img-fluid rounded shadow-sm" />
      </div>
      <button type="button" class="btn btn-dodgerblue mt-2" (click)="uploadCoverImage()">Upload Cover Image</button>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 mt-3"> <label class="form-label h4" style="color:dodgerblue;">Where?</label> <hr> </div>
    <div class="col-md-6">
      <label for="is-online-event" class="form-label">Is Online Event</label>
      <input id="is-online-event" type="checkbox" formControlName="IsOnlineEvent" class="form-check-input ms-2" />
    </div>
    <div class="col-md-6">
      <label for="country-code" class="form-label">Country Code</label>
      <input id="country-code" formControlName="CountryCode" class="form-control" placeholder="Country Code" />
      <span *ngIf="createEventForm.get('CountryCode')?.invalid && createEventForm.get('CountryCode')?.touched" class="text-danger">Country Code is required</span>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label for="province" class="form-label">Province</label>
      <input id="province" formControlName="Province" class="form-control" placeholder="Enter province" />
    </div>
    <div class="col-md-6">
      <label for="city" class="form-label">City</label>
      <input id="city" formControlName="City" class="form-control" placeholder="Enter city" />
      <span *ngIf="createEventForm.get('City')?.invalid && createEventForm.get('City')?.touched" class="text-danger">City is required</span>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label for="district" class="form-label">District</label>
      <input id="district" formControlName="District" class="form-control" placeholder="Enter district" />
    </div>
    <div class="col-md-6">
      <label for="address" class="form-label">Address</label>
      <textarea id="address" formControlName="Address" class="form-control" placeholder="Enter address">damlatas magarasi girisi, plaj</textarea>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label for="zip-code" class="form-label">Zip Code</label>
      <input id="zip-code" formControlName="ZipCode" class="form-control" placeholder="Enter zip code" />
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 mt-3"> <label class="form-label h4" style="color:dodgerblue;">Contact Links</label> <hr> </div>
    <div class="col-md-6">
      <label for="instagram" class="form-label">Instagram URL</label>
      <input id="instagram" formControlName="InstagramUrl" class="form-control" placeholder="Enter Instagram URL" />
    </div>
    <div class="col-md-6">
      <label for="facebook" class="form-label">Facebook URL</label>
      <input id="facebook" formControlName="FacebookUrl" class="form-control" placeholder="Enter Facebook URL" />
    </div>
    <div class="col-md-6">
      <label for="youtube" class="form-label">YouTube URL</label>
      <input id="youtube" formControlName="YoutubeUrl" class="form-control" placeholder="Enter YouTube URL" />
    </div>
    <div class="col-md-6">
      <label for="website" class="form-label">Website URL</label>
      <input id="website" formControlName="WebSiteUrl" class="form-control" placeholder="Enter website URL" />
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 mt-3"> <label class="form-label h4" style="color:dodgerblue;">When?</label> <hr> </div>
    <div class="col-md-6">
      <label for="starts-at" class="form-label">Starts At</label>
      <input id="starts-at" type="datetime-local" formControlName="StartsAt" class="form-control" />
      <span *ngIf="createEventForm.get('StartsAt')?.invalid && createEventForm.get('StartsAt')?.touched" class="text-danger">Starts At is required</span>
    </div>
    <div class="col-md-6">
      <label for="ends-at" class="form-label">Ends At</label>
      <input id="ends-at" type="datetime-local" formControlName="EndsAt" class="form-control" />
      <span *ngIf="createEventForm.get('EndsAt')?.invalid && createEventForm.get('EndsAt')?.touched" class="text-danger">Ends At is required</span>
    </div>
  </div>




 <!-- Ticket Options -->
  <div class="row mb-3">
    <div class="col-12 mt-3">
      <label class="form-label h4" style="color:dodgerblue;">Free/Paid?</label> <hr>
      <div class="row">
        <div class="col-md-6">
          <label for="is-free-event" class="form-label">Is Free Event</label>
          <input id="is-free-event" type="checkbox" formControlName="IsFreeEvent" class="form-check-input ms-2" />
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="!createEventForm.get('IsFreeEvent')?.value">
    <div class="col-12 mt-3">
      <label class="form-label h4" style="color:dodgerblue;">Ticket Options</label> <hr>
      <div *ngFor="let ticketOption of ticketOptions.controls; let i = index" [formGroupName]="i" class="mb-3">
        <div class="row">
          <div class="col-md-4">
            <label for="ticket-name-{{i}}" class="form-label">Ticket Name</label>
            <input id="ticket-name-{{i}}" formControlName="Name" class="form-control" placeholder="Ticket Name" />
          </div>
          <div class="col-md-3">
            <label for="ticket-price-{{i}}" class="form-label">Ticket Price</label>
            <input id="ticket-price-{{i}}" formControlName="Price" class="form-control" type="number" placeholder="Price" />
          </div>
          <div class="col-md-3">
            <label for="ticket-quota-{{i}}" class="form-label">Ticket Quota</label>
            <input id="ticket-quota-{{i}}" formControlName="Quota" class="form-control" type="number" placeholder="Quota" />
          </div>
          <div class="col-md-2">
            <button type="button" class="btn btn-danger mt-4" (click)="removeTicketOption(i)">Remove</button>
          </div>
        </div>
        <div class="mt-2">
          <label for="ticket-description-{{i}}" class="form-label">Ticket Description</label>
          <textarea id="ticket-description-{{i}}" formControlName="Description" class="form-control" placeholder="Description"></textarea>
        </div>
      </div>
      <button type="button" class="btn btn-primary mt-3" (click)="addTicketOption()">Add Ticket Option</button>
    </div>
  </div>

  <button type="submit" class="btn btn-dodgerblue w-100">Create Event</button>
</form>
