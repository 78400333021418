import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { 
  SignUpInput, 
  SignUpOutput, 
  signUp, 
  signIn, 
  autoSignIn, 
  fetchUserAttributes, 
  fetchAuthSession, 
  signOut, 
  confirmSignUp, type ConfirmSignUpInput,
  resetPassword, type ResetPasswordOutput,
  confirmResetPassword, type ConfirmResetPasswordInput
}
from 'aws-amplify/auth';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import awsExports from '../../aws-exports';
import { trigger } from '@angular/animations';

type SignUpParams = {
  username: string;
  password: string;
  email: string;
  name: string;  
  birthdate: string;
  phone_number: string;
};

export interface SignUpForm {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  birthdate: string;
  gender: string;
}

export interface currentUser {
  isAuthenticated: boolean;
  email: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})


export class MyAuthService {

  currentUser: currentUser = { isAuthenticated: false, email: '', name: '' };  


  constructor(public authenticator: AuthenticatorService) 
  {
    Amplify.configure(awsExports);
  }

  async signin(username: string, password: string): Promise<any>
  {
    if (!username || !password) {return Promise.reject('Username and password are required.');}

    try 
    { 
        const { isSignedIn, nextStep } = await signIn({ username, password });
        console.log('isSignedIn', isSignedIn);
        console.log('nextStep', nextStep);          
        await this.handleFetchUserAttributes();
        return Promise.resolve('Sign-in successful');
    } 
    catch (error) 
    {  
      console.log('error signing in', error);  
      return Promise.reject('Sign-in failed! ' + error);
    }
  }

  async signout() 
  {
    try 
    {
      await signOut();
      this.currentUser.isAuthenticated = false;
      this.currentUser.email = '';
      this.currentUser.name = '';
    } 
    catch (error) 
    {  
      console.log('error signing out: ', error);  
    }
  }
    
  async signup(signUpForm: SignUpForm) : Promise<any> 
  {
    console.log('Signing up with:', signUpForm);

    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: signUpForm.email,
        password: signUpForm.password,
        options: {
          userAttributes: {
            email: signUpForm.email,
            phone_number: signUpForm.phoneNumber, // E.164 number convention
            name: signUpForm.name,
            birthdate: signUpForm.birthdate          
          },
          // optional
          autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        }
      });

      console.log('userId', userId);  
      console.log('isSignUpComplete', isSignUpComplete);  
      console.log('nextStep', nextStep);
      return Promise.resolve(nextStep.signUpStep);
    }
    catch (error) 
    {
      console.log('Error signing up:', error);   
      return Promise.reject(error);
    }
  }

  async signupConfirmation({username,  confirmationCode}: ConfirmSignUpInput)  : Promise<any> 
  {
    try 
    {
      const { isSignUpComplete, nextStep } = await confirmSignUp({username, confirmationCode});
      console.log(`isSignUpComplete: ${isSignUpComplete}`);
      console.log(`nextStep: ${nextStep}`);
      console.log(`nextStep: ${nextStep.signUpStep}`);
      return Promise.resolve(nextStep.signUpStep);
    } 
    catch (error) 
    {
      console.log('error confirming sign up::', error);
      return Promise.reject(error);
    }
  }

  async resetpassword(username:string)  : Promise<any> 
  {
    try 
    {
      const output = await resetPassword({ username });
      console.log('output', output);
      
      const { nextStep } = output;  
      switch (nextStep.resetPasswordStep) 
      {  
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':  
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          Promise.resolve(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`);
          break;  
        case 'DONE':  
          Promise.resolve('DONE');
          break;
      }
    } 
    catch (error) 
    {
      console.log('error confirming sign up::', error);
      return Promise.reject(error);
    }
  }

  async confirmresetpassword({username,confirmationCode,newPassword}: ConfirmResetPasswordInput): Promise<any> 
  {
    try 
    {
      var response = await confirmResetPassword({ username, confirmationCode, newPassword });  
      console.log('response', response);
      return Promise.resolve(response);
    } 
    catch (error) 
    {  
      console.log(error);  
      return Promise.reject(error);
    }  
  }

  async handleFetchUserAttributes() {
    try {

      const userAttributes = await fetchUserAttributes();
      this.currentUser.email = userAttributes.email ?? '';
      this.currentUser.name = userAttributes.name ?? '';
      this.currentUser.isAuthenticated = true;

    } catch (error) {console.error(error);throw error;}
  }
}
