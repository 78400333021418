import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventDetailVM } from '../../../../models/event';
import { ViewEventService } from '../../../../services/view-event.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrl: './event-details.component.css'
})
export class EventDetailsComponent implements OnInit, AfterViewInit {

isLoading = true;
errorMessage = '';
public id:string = '1234';
response: EventDetailVM | null = null;
countdownTimer: string = '';

constructor(private route: ActivatedRoute, public viewEventService: ViewEventService) 
{
   this.id = this.route.snapshot.params['id']; // Get the id parameter from the rout
   // get events details from backend here//
   // get events details from backend here..
}

ngOnInit() 
{

}

ngAfterViewInit(): void {
  this.viewEventService.getEventDetails(this.id)
  .subscribe({
      next: (p) => {
          console.log("Response received!");           
          this.response = p;
          console.log("response:");
          console.log(p);
          console.log("mapped response:");
          console.log(this.response);
          this.isLoading = false;
          this.startCountdown();
          this.initMap(this.response.Lat, this.response.Lng);
      },
      error: (error) => {
          this.errorMessage = error;
          this.isLoading = false;
      },
  });
}

startCountdown() {
  setInterval(() => {
    const timeDifference = new Date('2025-02-01').getTime() - new Date().getTime();
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    this.countdownTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }, 1000);
}


async initMap(eventLat:number, eventLng:number) {
  // Request needed libraries.
  console.log("initMap running...");
  const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
  const map = new Map(document.getElementById('map') as HTMLElement, { center: { lat: eventLat, lng: eventLng }, zoom: 14, mapId: '4504f8b37365c3d0', });  
  const marker1 = new AdvancedMarkerElement({ map, position: { lat: eventLat, lng: eventLng }, });
  console.log("initMap completed");
}



}