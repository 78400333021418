import { Component } from '@angular/core';

@Component({
  selector: 'app-visitor-report',
  templateUrl: './visitor-report.component.html',
  styleUrl: './visitor-report.component.css'
})
export class VisitorReportComponent {

}
