import { Component } from '@angular/core';

@Component({
  selector: 'app-calendarview',
  templateUrl: './calendarview.component.html',
  styleUrl: './calendarview.component.css'
})
export class CalendarviewComponent {

}
