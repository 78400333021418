import { Component } from '@angular/core';

@Component({
  selector: 'app-view-events',
  templateUrl: './view-events.component.html',
  styleUrl: './view-events.component.css'
})
export class ViewEventsComponent {
view = 'map';

changeView()
{

}

}