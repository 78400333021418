import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { OrganizerOpsService } from '../../../services/organizer-ops.service';
import { CreateEvent, EventType } from '../../../models/create-event';
import { ViewEventService } from '../../../services/view-event.service';

declare var google: any;

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrl: './create-event.component.css'
})
export class CreateEventComponent implements OnInit, AfterViewInit {

  @ViewChild('mapContainer') mapContainer!: ElementRef;
  @ViewChild('pacInput') pacInput!: ElementRef;

  createEventForm: FormGroup;
  ticketOptionForm: FormGroup;
  isTicketOptionModalVisible: boolean = false;
  currentEditIndex: number | null = null;
  coverImageUrl: string | ArrayBuffer | null = null;
  coverImageFile: File | null = null;
  coverImgURL: string = '';
  eventTypes = Object.values(EventType);

  map!: google.maps.Map;
  marker!: google.maps.Marker;
  infowindow!: google.maps.InfoWindow;
  geocoder!: google.maps.Geocoder;

  ImageUploading:boolean = false;
  ImageUploaded:boolean = false;
  ImageUploadInitial:boolean = true;
  ImageUploadUpdate:boolean = false;
  ImageUploadError:boolean = false;
  ImageUploadErrorMsg:string = ``;
  hasFormBeenSubmitted: boolean = false;

  constructor(
    private ngZone: NgZone, 
    private fb: FormBuilder, 
    private organizerOpsService: OrganizerOpsService, 
    public viewEventService: ViewEventService) 
    {
      this.createEventForm = this.fb.group({
      Title: ['', Validators.required],
      Description: [''],
      
      CountryCode: ['', Validators.required],
      CountryName: ['', Validators.required],
      StateProvinceRegion: ['', Validators.required],
      CityTown: ['', Validators.required],      
      StreetBuildingEtc: ['', Validators.required],      
      PlaceName: ['', Validators.required],
      PostalZIPCode: ['', Validators.required],
      IsOnlineEvent: [false],
      Lat: [0],
      Lng: [0],
      InstagramUrl: [''],
      FacebookUrl: [''],
      YoutubeUrl: [''],
      WebSiteUrl: [''],
      Type: ['', Validators.required],
      Categories: [[], Validators.required],
      StartsAt: ['', Validators.required],
      EndsAt: ['', Validators.required],
      IsFreeEvent: [true],
      TicketOptions: this.fb.array([])
    });

    this.ticketOptionForm = this.fb.group({
      Name: ['', Validators.required],
      Price: ['', [Validators.required, Validators.min(0)]],
      Description: [''],
      Quota: ['', [Validators.required, Validators.min(0)]]
    });
  }

  ngOnInit(): void {}

  get ticketOptions(): FormArray {
    return this.createEventForm.get('TicketOptions') as FormArray;
  }

  openTicketOptionModal(index: number | null = null): void {
    this.isTicketOptionModalVisible = true;
    this.currentEditIndex = index;

    if (index !== null) {
      const ticketOption = this.ticketOptions.at(index).value;
      this.ticketOptionForm.setValue(ticketOption);
    } else {
      this.ticketOptionForm.reset();
    }

    this.ticketOptionForm.markAsUntouched();
  }

  closeTicketOptionModal(): void {
    this.isTicketOptionModalVisible = false;
    this.ticketOptionForm.reset();
  }

  addOrUpdateTicketOption(): void {
    if (this.ticketOptionForm.invalid) {
      this.ticketOptionForm.markAllAsTouched();
      return;
    }

    if (this.currentEditIndex === null) {
      this.ticketOptions.push(this.fb.group(this.ticketOptionForm.value));
    } else {
      this.ticketOptions.at(this.currentEditIndex).patchValue(this.ticketOptionForm.value);
    }

    this.closeTicketOptionModal();
  }

  removeTicketOption(index: number): void {
    this.ticketOptions.removeAt(index);
  }

  submitForm(): void {

    this.hasFormBeenSubmitted = true;

    if (this.createEventForm.invalid) {
      this.createEventForm.markAllAsTouched();
      return;
    }

    console.log('Form Submitted', this.createEventForm.value);

    const eventData: CreateEvent = {
      Title: this.createEventForm.value.Title,
      Description: this.createEventForm.value.Description,
      CoverImgURL: this.coverImgURL,
      IsOnlineEvent: this.createEventForm.value.IsOnlineEvent,
      CountryCode: this.createEventForm.value.CountryCode,
      StateProvinceRegion: this.createEventForm.value.StateProvinceRegion,
      CityTown: this.createEventForm.value.CityTown,
      StreetBuildingEtc: this.createEventForm.value.StreetBuildingEtc,
      PlaceName: this.createEventForm.value.PlaceName,
      PostalZIPCode: this.createEventForm.value.PostalZIPCode,
      Lat:this.createEventForm.value.Lat,
      Lng:this.createEventForm.value.Lng,
      InstagramUrl: this.createEventForm.value.InstagramUrl,
      FacebookUrl: this.createEventForm.value.FacebookUrl,
      YoutubeUrl: this.createEventForm.value.YoutubeUrl,
      WebSiteUrl: this.createEventForm.value.WebSiteUrl,
      Type: this.createEventForm.value.Type,
      CategoryIds: this.createEventForm.value.Categories,
      StartsAt: new Date(this.createEventForm.value.StartsAt).toISOString(),
      EndsAt: new Date(this.createEventForm.value.EndsAt).toISOString(),
      TicketOptions: this.createEventForm.value.TicketOptions
    };

    console.log('CreateEvent object: ', eventData);

    this.organizerOpsService.createEvent(eventData).subscribe(
      (response) => {
        console.log('Event created successfully:', response);
      },
      (error) => {
        console.error('Error creating event:', error);
      }
    );
    

  }


  onCoverImageSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {

      this.ImageUploading = true;
      this.ImageUploaded = false;
      this.ImageUploadInitial = false;
      this.ImageUploadUpdate = false;

      this.coverImageFile = input.files[0];

      const reader = new FileReader();
      reader.onload = () => { this.coverImageUrl = reader.result; };
      reader.readAsDataURL(this.coverImageFile);


      if (!this.coverImageFile) return;

      this.organizerOpsService.getSignedUrlToUploadCoverImg().subscribe(
        (response) => {
          const signedUrl = response.Result;
          console.log('Signed URL:', signedUrl);
  
          this.organizerOpsService.uploadCoverImageToS3(signedUrl, this.coverImageFile as File).subscribe(
            () => {
              this.coverImgURL = signedUrl.split('?')[0];
              console.log('Cover image uploaded successfully:', this.coverImgURL);
              this.ImageUploading = false;
              this.ImageUploaded = true;
              this.ImageUploadUpdate = true;              
            },
            (error) => {
              console.error('Error uploading cover image:', error);
              this.ImageUploading = false;
            }
          );
        },
        (error) => {
          console.error('Error fetching signed URL:', error);
          this.ImageUploading = false;
          this.ImageUploadInitial = true;
          this.ImageUploadError = true;
          this.ImageUploadErrorMsg = 'Error fetching signed URL:' + error;
        }
      );
      
    }
  }

 
  

  ngAfterViewInit(): void {
    const center = { lat: 51, lng: 8 };
    this.map = new google.maps.Map(this.mapContainer.nativeElement, {
      center: center,
      zoom: 6,
      mapTypeControl: false,
      streetViewControl: false,
    });

    const autocomplete = new google.maps.places.Autocomplete(this.pacInput.nativeElement, {
      fields: ["place_id", "geometry", "formatted_address", "name", "address_components"],
    });

    autocomplete.bindTo('bounds', this.map);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.pacInput.nativeElement);

    this.infowindow = new google.maps.InfoWindow();
    this.marker = new google.maps.Marker({
      map: this.map,
      draggable: true,
      position: center
    });
    this.geocoder = new google.maps.Geocoder();

    this.marker.addListener("click", () => {
      this.infowindow.open(this.map, this.marker);
    });

    this.map.addListener("click", (event: google.maps.MapMouseEvent) => {
      this.ngZone.run(() => {
        this.setMarkerPosition(event.latLng!);
      });
    });

    this.marker.addListener("dragend", (event: google.maps.MapMouseEvent) => {
      this.ngZone.run(() => {
        this.setMarkerPosition(event.latLng!);
      });
    });

    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          this.setMarkerPosition(place.geometry.location, place);
          this.map.setZoom(17);  // Set zoom level to 17 after a place is selected
        } else {
          this.infowindow.setContent(`<div>No location found.</div>`);
          this.infowindow.open(this.map, this.marker);
          this.map.setZoom(6);  // Optionally, reset to a broader zoom if no location is found
        }
      });
    });
}


private setMarkerPosition(location: google.maps.LatLng, place?: google.maps.places.PlaceResult) 
{
  this.marker.setPosition(location);
  this.map.panTo(location);

  if (place) {
    this.setInfoWindowContent(place);
  } else {
    this.geocoder.geocode({ location: location }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        this.ngZone.run(() => {
          this.setInfoWindowContent(results[0]);
        });
      } else {
        this.infowindow.setContent('<div>No results found</div>');
        this.infowindow.open(this.map, this.marker);
      }
    });
  }
}


private setInfoWindowContent(place: google.maps.places.PlaceResult | google.maps.GeocoderResult) 
{
  const lat = this.marker.getPosition()!.lat();
  const lng = this.marker.getPosition()!.lng();
  console.log(`Coordinates of the selected address. lat:${lat}, lng:${lng}`);

  let addressComponents = place.address_components;
  let city = '', state = '', zipcode = '', countryCode = '', countryName = '', placeName = '', street = '', streetNumber = '';

  // Extract place name and ID if available
  if ('name' in place && place.name) {
    placeName = place.name;
  }

  console.log(addressComponents);
  
  if (addressComponents) {
    addressComponents.forEach(component => {
        const types = component.types;
        if (types.includes('country')) {
            countryCode = component.short_name;  
            countryName = component.long_name; 
        }
        if (types.includes('locality')) {
            city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
            state = component.short_name;
        }
        if (types.includes('postal_code')) {
            zipcode = component.short_name;
        }
        if (types.includes('route')) {
            street = component.long_name;
        }
        if (types.includes('street_number')) {
            streetNumber = component.long_name;
        }
        // Check for administrative_area_level_2 if city is still empty
        if (types.includes('administrative_area_level_2') && !city) {
            city = component.long_name;
        }
    });
}


  const streetBuildingEtc = `${streetNumber} ${street}`.trim(); 

  // Update form with the extracted components
  this.createEventForm.patchValue({
    CountryCode: countryCode,
    CountryName: countryName,
    StateProvinceRegion: state,
    CityTown: city,
    StreetBuildingEtc: streetBuildingEtc, 
    PlaceName: placeName,
    PostalZIPCode: zipcode,
    Lat: lat,
    Lng: lng
  });

  console.log(`CountryCode:${countryCode}`);
  console.log(`CreateEventForm CountryCode:${this.createEventForm.value.CountryCode}`);
  console.log(`CreateEventForm lat:${this.createEventForm.value.Lat}`);
  console.log(`CreateEventForm lng:${this.createEventForm.value.Lng}`);

  const contentElement = document.createElement('div');
  contentElement.className="container-fluid";
  contentElement.innerHTML = `
    <div class="row">
      <div class="col-12" style='font-size:larger'> ${placeName} ${streetBuildingEtc} ${city} ${state} ${zipcode} ${countryCode} </div>
      <div class="col-12 text-center mt-2"><button id="continueButton" onClick="window.scrollBy(0, 280);" class="btn btn-primary">Continue with this address</button></div>
    </div>`;

  this.infowindow.setContent(contentElement);
  this.infowindow.open(this.map, this.marker);

  // Manually add event listener to the button
  const continueButton = contentElement.querySelector('#continueButton');
  if (continueButton) {
    continueButton.addEventListener('click', () => {
      this.ngZone.run(() => {
        this.closeInfoWindow();
      });
    });
  } else {
    console.error("The continue button was not found in the InfoWindow content.");
  }
}


// Method to close the marker's InfoWindow
closeInfoWindow(): void {
if (this.infowindow) {
  window.scrollBy(0, 280);
  this.infowindow.close();
  this.marker.setIcon('');

  //const icon = { url: 'assets/images/marker-ok.png',  scaledSize: new google.maps.Size(64, 64)};
  //this.marker.setIcon(icon);

}
}


}