import { Component, OnInit } from '@angular/core';
import { BackendService } from '../../../../services/backend.service';
import { Page, EventListItem } from '../../../../models/event';

@Component({
  selector: 'app-listview',
  templateUrl: './listview.component.html',
  styleUrl: './listview.component.css'
})
export class ListviewComponent {

  isLoading = true;
  errorMessage = '';
  response: Page<EventListItem> | null = null;
  country_filter:string = '';
  type_filter:string = '';
  status_filter:string = '';
  source_filter:string = '';
  show_filter:boolean = false;

  constructor(private eventService: BackendService) { }

  ngOnInit() {

    this.isLoading = true;
    this.loadEvents();
  }


  loadEvents(pageNo: number = 1) {
    this.isLoading = true;
    this.eventService.getEventList(pageNo, this.country_filter, this.type_filter, this.source_filter)
    .subscribe({
        next: (p) => {
            console.log("Response received!");           
            this.response = p;
            console.log(this.response);
            this.isLoading = false;
        },
        error: (error) => {
            this.errorMessage = error;
            this.isLoading = false;
        },
    });
}



previous() { 
  this.loadEvents(this.response!.PageNo - 1); 
}

next() { 
  this.loadEvents(this.response!.PageNo + 1); 
}


  
}
