<form [formGroup]="createEventForm" (ngSubmit)="onSubmit()">
    <app-input 
      label="Country" 
      formControlName="CountryName" 
      [formGroup]="createEventForm" 
      [isRequired]="true" 
      validationMessage="Country is required, please pick an address from the map">
    </app-input>
  
    <app-input 
      label="State/Province/Region" 
      formControlName="StateProvinceRegion" 
      [formGroup]="createEventForm" 
      [isRequired]="true" 
      validationMessage="State/Province/Region is required">
    </app-input>
  
    <app-input 
      label="City/Town" 
      formControlName="CityTown" 
      [formGroup]="createEventForm" 
      [isRequired]="true" 
      validationMessage="City/Town is required">
    </app-input>
  
    <button type="submit" class="btn btn-primary">Submit</button>
  </form>
  