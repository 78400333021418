<div class="container">
    <div class="row">

        <!-- Using col-md-* classes to specify how elements should behave on medium and larger screens -->
        <div class="col-12 col-md-6 d-none d-lg-block">
            <h1 style="color:dodgerblue">Upcoming Events</h1>
        </div>

        <div class="col-12 col-md-6">
            <div id="filter-and-view-type" class="d-flex justify-content-md-end align-items-center">               
               
                <app-filter ></app-filter>               
               
                <select class="form-select mt-3 mt-lg-0" [(ngModel)]="view" (change)="changeView()">
                    <option value="list">ListView</option>
                    <option value="card" selected>CardView</option>
                    <option value="map">MapView</option>
                    <option value="calendar">CalendarView</option>
                </select>                
                
            </div>
        </div>        
    </div>
    

    <!-- Component load based on view state -->
    <app-listview *ngIf="view=='list'"></app-listview>
    <app-cardview *ngIf="view=='card'"></app-cardview>
    <app-mapview *ngIf="view=='map'"></app-mapview>
    <app-calendarview *ngIf="view=='calendar'"></app-calendarview>
</div>
