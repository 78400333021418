
<div class='container'>
    <div class='row'>
        <div class='col-12 text-center mb-3'>
            <h1 style="color:dodgerblue"> <i class="bi bi-telephone-outbound"></i> contact us   </h1>
            <h5> please click one of the following channel to reach us   <br /><br />  </h5>               
        </div>
        <div class='col-sm-3 col-6 text-center mt-4'>
              <a href="{{AppInfo.AddressUrl}}" target="_blank">
                <i class="bi bi-geo-alt display-1"></i>
                <h4> address  </h4>
                {{AppInfo.Address}}</a>            
        </div>
         <div class='col-6 col-sm-3 text-center mt-4'>
              
              <a href="{{AppInfo.TelegramUrl}}" target="_blank">
                <i class="bi bi-telegram display-1"></i>
                  <h4>  telegram  </h4>  
                  {{AppInfo.TelegramCaption}}
              </a>    
        </div>
         <div class='col-6 col-sm-3 text-center mt-4' style="z-index:100;">            
              <a href="{{AppInfo.InstagramUrl}}" target="_blank">
                  <i class="bi bi-instagram display-1"></i>
              <h4>  instagram  </h4>  
              {{AppInfo.InstagramCaption}} </a> 
        </div>

        <div class='col-6 col-sm-3 text-center mt-4' style="z-index:100;">            
              <a href="mailto:@Constants.AppInfo.email" target="_blank">
                <i class="bi bi-envelope display-1"></i>
                <h4>  email  </h4>
                {{AppInfo.email}} </a> 
        </div>

        <div class='col-12 text-center mt-4' style="z-index:100;">
            <a href="mailto:{{AppInfo.email}}" target="_blank">


                
                <br>
                <br />  This is the application is developed for you to find social events around you. <br> socialize, have fun, create professional network. All up to you!  <br />
                <br>
                {{AppInfo.ownership_declareation}} , {{AppInfo.Domain}}, {{AppInfo.CompanyName}}

            </a>
        </div>


        <div class='col-12 col-lg-12 text-center pt-5'>

              <img src="assets/images/event1.jpg" class="mt-5" width="300" style="margin-top:0px; border-radius:15px; z-index:-1;" />
            <br />            
            <br />
            <p class="h6"> Please feel free to contact us for any help or feedback. We can reply you on Mon-Fri 10.00-16.00 </p> <br />
            <br />
        </div>
    </div> 
</div>

