<div class="cartdiv" (click)="openDetails(card.Id)" style="cursor: pointer;">
    <div class="row" style=" overflow:hidden !important;">
        <div class="col-12">
            <div class="Free"> free </div>
            <img class="CoverImg" src={{card.CoverImgURL}}/>                                         
        </div>

        <div class="col-12 pt-2"  style="z-index:2">
            <h5 style="text-align:center;text-shadow: 1px 1px #FFFFFF;"> {{card.Title}} </h5> 
            <i class="bi bi-geo-alt"></i>  {{card.ShortAddress}}
            <br/> <i class="bi bi-calendar3-week"></i> {{card.StartTime}}            
            <div class="ticket"><i class="bi bi-ticket-perforated"></i> {{card.TicketPrice}} </div>
        </div>
    </div>
</div>

<ng-content></ng-content>