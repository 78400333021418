
<div class="search-container" (click)="showFilterPanel.set(!showFilterPanel())">
      <input type="text" placeholder="Search events..." class="search-input">
      <button class="search-button">
          <i class="bi bi-funnel"></i>
      </button>
  </div>
  


<div *ngIf="showFilterPanel()" class="overlay"></div>

<div *ngIf="showFilterPanel()" class="filter-panel">
    <div class="filter-content">

      <label for="">Country</label>
      <ng-select id="country"
            [items]="viewEventService.filterData().Countries"
            bindLabel="Name"
            bindValue="Code"
            placeholder="Select a country"
            [(ngModel)]="viewEventService.country_selected">
      </ng-select>

      <label for="">Province</label>
      <input type="text" placeholder="Province" />

      <label for="">Category</label>
      <ng-select id="category"
            [items]="viewEventService.filterData().Categories"
            bindLabel="Name"
            bindValue="Id"
            placeholder="Select a category"
            groupBy="Parent"
            [multiple]="true"
            [(ngModel)]="viewEventService.categories_selected">
      </ng-select>

      <label for="">Type</label>
      <ng-select id="eventType"
            [items]="viewEventService.filterData().Types"
            bindLabel="Name"
            bindValue="Id"
            placeholder="Select an event type"
            [multiple]="true"
            [(ngModel)]="viewEventService.type_selected">
      </ng-select>


      <label for="">Start Date</label>
      <input type="date" placeholder="Start Date" />
      <br>
      <button (click)="applyFilters()">Apply</button>
      <button (click)="toggleFilterPanel()">Hide</button>
    </div>
  </div>