<div class="container mt-5">
  <h2 class="text-primary">Create Event</h2>
  <form id="address-form" action="" method="get" autocomplete="off">
    <div class="form-group">
      <label for="title">Deliver to*</label>
      <input type="text" class="form-control" id="ship-address" name="ship-address" [(ngModel)]="address1" required autocomplete="off" #shipAddress/>
    
      <label for="title">City*</label>
      <input type="text" class="form-control" id="address2" name="address2" [(ngModel)]="address2"/>
    
      <label for="title">Apartment, unit, suite, or floor #</label>
      <input type="text" class="form-control" id="locality" name="locality" [(ngModel)]="locality" required/>
    
    
      <label for="title">State/Province*</label>
      <input type="text" class="form-control" id="state" name="state" [(ngModel)]="state" required />
    
    
      <label for="title">Postal code*</label>
      <input type="text" class="form-control" id="postcode" name="postcode" [(ngModel)]="postcode" required />
    
    
      <label for="title">Country/Region*</label>
      <input type="text" class="form-control" id="country" name="country" [(ngModel)]="country" required />    
    </div>

    <button type="button" class="my-button" class="btn btn-primary m-3">Save address</button>
    <input type="reset" value="Clear form" class="btn btn-success m-3"/>
  </form>




  