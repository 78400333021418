<div class="input-bx">
    <input 
        type="text"
        [formControl]="control" 
        [ngClass]="{'is-invalid': control?.invalid && (control?.dirty || control?.touched)}" 
        [(ngModel)]="value"
        (input)="onInput($event)"
        required />
    <label [for]="formControlName">{{ label }}</label>
    <i *ngIf="control?.invalid && (control?.dirty || control?.touched)" class="bi bi-exclamation-circle invalid-icon"></i>
    <div *ngIf="control?.invalid && (control?.dirty || control?.touched)" class="invalid-feedback"> {{ validationMessage }} </div>
</div>
