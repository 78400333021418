 <div id='footer' class='p-2 mt-5'>    
        
    <div class="container pt-3">
        <div class="row">
            <div class="col-md-4 col-12 pt-4">
                <a class="title">   for_organizers  </a>
                <hr />
                <a class='footer-link' onclick="CreateEvent()">  publish_your_event  </a>
                <a class='footer-link' onclick="">   ticket_management   </a>
                <a class='footer-link' onclick="">   promote_events </a>
                <a class='footer-link' onclick="">  sell dance equipments  </a>
                <a class='footer-link' onclick="">  Find instructor </a>

            </div>
            <div class="col-md-4 col-12 pt-4">
                <a class="title">  for_dancers  </a>
                <hr />
                <a class='footer-link' onclick="window.location.href='/Event/index'"> explore_events  </a>
                <a class='footer-link' onclick="window.location.href='/Event/index'"> find_discounted_tickets  </a>
                <a class='footer-link' onclick="window.location.href='/Event/index'"> find_other_dancers    </a>
                <a class='footer-link' onclick="window.location.href='/Event/index'"> find_dance_schools   </a>
            </div>
            
            <div class="col-md-4 col-12 pt-4">

                <a class="title"> who_we_are  </a>
                <hr />

                <a class='footer-link' href='/about'>  <i class="bi bi-card-checklist"></i>   aboutus  </a>                    
                <a class='footer-link' href='/contact-us'> <i class='bi bi-telephone'></i>  contact_us   </a>
                <a class='footer-link' href='#' onclick="feedback()"> <i class="bi bi-chat-dots"></i> give_us_feedback </a>
                <a class='footer-link' href='/terms-of-use'>  <i class="bi bi-card-checklist"></i>  terms_of_use  </a>
                <a class='footer-link' href='/privacy'>  <i class="bi bi-card-checklist"></i>  privacy_policy  </a>
                          
            </div>

            

             <div class="col-12 pt-4 text-center" style="font-size:12px">
                <p style='text-align:center;' id='CopyRightText'> {{appInfo.Domain}} 2024 - all rights Reserved. </p>
            </div>

            <div class="col-12 d-block d-sm-none">
                <br><br><br><br><br>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-12 d-block text-center  mt-3">
            <img style="width: 90px;" src="assets/images/stripe.png" /><br>                   
            <br>
        </div>
    </div>
</div>