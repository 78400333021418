export interface CreateEvent {
    Title: string;
    Description?: string;
    CoverImgURL?: string;
    IsOnlineEvent: boolean;
    CountryCode: string;
    StateProvinceRegion?: string;
    CityTown: string;
    StreetBuildingEtc?: string;
    PlaceName?: string;
    Lat: number;
    Lng: number;
    PostalZIPCode?: string;
    InstagramUrl?: string;
    FacebookUrl?: string;
    YoutubeUrl?: string;
    WebSiteUrl?: string;
    Type: EventType;
    CategoryIds?: number[];
    StartsAt: string;
    EndsAt: string;
    TicketOptions?: TicketOption[];
  }


  export interface CreateEventTest {
    Title: string;
    Description?: string;    
    YoutubeUrl?: string;
    WebSiteUrl?: string;
    TicketOptions?: TicketOption[];
  }

  export interface TicketOption {
    Name: string;
    Price: number;
    Description?: string;
    Quota: number;
  }

  export enum EventType {
    Meetup = 'Meetup',
    Networking = 'Networking',
    Workshop = 'Workshop',
    Course = 'Course',
    Training = 'Training',
    Festival = 'Festival',
    Party = 'Party'
  }
  