import { Component } from '@angular/core';

@Component({
  selector: 'app-sell-your-tickets',
  templateUrl: './sell-your-tickets.component.html',
  styleUrl: './sell-your-tickets.component.css'
})
export class SellYourTicketsComponent {

}
