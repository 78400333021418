import { Component, Input } from '@angular/core'; // Note the capitalization of 'Input'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'] // Corrected from 'styleUrl' to 'styleUrls'
})
export class LoadingComponent {
  @Input() show: boolean = false; // Use 'show' as an input property
  @Input() color: string = 'dodgerblue'; 
}
