import { Component, Input, forwardRef } from '@angular/core';
import { FormGroup, FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor {
  @Input() label: string | undefined;
  @Input() formControlName: string | undefined;
  @Input() formGroup: FormGroup | undefined;
  @Input() isRequired: boolean = false;
  @Input() validationMessage: string = "This field is required";

  value: string | undefined;

  onChange: any = () => {};
  onTouch: any = () => {};

  get control(): FormControl {
    return this.formGroup?.get(this.formControlName || '') as FormControl || new FormControl();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if necessary
  }

  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.value = inputElement.value;
    this.onChange(inputElement.value);
    this.onTouch();
  }
}
