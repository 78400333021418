import { Component } from '@angular/core';
import { LoggingService } from '../../services/logging.service';
import { AlertService } from '../../services/alert.service';
import { AppInfo } from '../../models/static-data';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
  providers:[LoggingService]
})
export class FooterComponent {


  appInfo = AppInfo;
  alertService: AlertService;

  constructor(alertService: AlertService)
  {
    this.alertService = alertService;
  }
}