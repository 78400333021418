<div class="container mt-5">
  <h2 class="text-primary">Create Event</h2>
  <form [formGroup]="createEventForm" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="title">Title</label>
      <input
        type="text"
        id="title"
        formControlName="Title"
        class="form-control"
        [ngClass]="{'is-invalid': createEventForm.get('Title')?.invalid && createEventForm.get('Title')?.touched}"
      />
      <div *ngIf="createEventForm.get('Title')?.invalid && createEventForm.get('Title')?.touched" class="invalid-feedback">
        Title is required
      </div>
    </div>

    <div class="form-group">
      <label for="description">Description</label>
      <textarea id="description" formControlName="Description" class="form-control"></textarea>
    </div>

    <div class="form-group">
      <label for="youtubeUrl">Youtube URL</label>
      <input type="text" id="youtubeUrl" formControlName="YoutubeUrl" class="form-control" />
    </div>

    <div class="form-group">
      <label for="websiteUrl">Website URL</label>
      <input type="text" id="websiteUrl" formControlName="WebSiteUrl" class="form-control" />
    </div>

    <h3 class="text-primary mt-4">Ticket Options</h3>
    <table class="table table-striped mt-3">
      <thead>
        <tr>
          <th>Name</th>
          <th>Price</th>
          <th>Description</th>
          <th>Quota</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ticket of ticketOptions.controls; let i = index">
          <td>{{ ticket.get('Name')?.value }}</td>
          <td>{{ ticket.get('Price')?.value | currency }}</td>
          <td>{{ ticket.get('Description')?.value }}</td>
          <td>{{ ticket.get('Quota')?.value }}</td>
          <td>
            <button type="button" class="btn btn-outline-primary btn-sm" (click)="openTicketOptionModal(i)">Edit</button>
            <button type="button" class="btn btn-outline-danger btn-sm" (click)="removeTicketOption(i)">Remove</button>
          </td>
        </tr>
      </tbody>
    </table>
    <button type="button" class="btn btn-primary" (click)="openTicketOptionModal()">Add Ticket Option</button>

    <button type="submit" class="btn btn-success mt-4">Create Event</button>
  </form>

  <!-- Ticket Option Modal -->
  <div class="modal" tabindex="-1" [ngClass]="{'show': isTicketOptionModalVisible}" [ngStyle]="{'display': isTicketOptionModalVisible ? 'block' : 'none'}">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{currentEditIndex !== null ? 'Edit' : 'Add'}} Ticket Option</h5>
          <button type="button" class="btn-close" (click)="closeTicketOptionModal()"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="ticketOptionForm">
            <div class="form-group">
              <label for="name">Name</label>
              <input
                type="text"
                id="name"
                formControlName="Name"
                class="form-control"
                [ngClass]="{'is-invalid': ticketOptionForm.get('Name')?.invalid && ticketOptionForm.get('Name')?.touched}"
              />
              <div *ngIf="ticketOptionForm.get('Name')?.invalid && ticketOptionForm.get('Name')?.touched" class="invalid-feedback">
                Name is required
              </div>
            </div>

            <div class="form-group">
              <label for="price">Price</label>
              <input
                type="number"
                id="price"
                formControlName="Price"
                class="form-control"
                [ngClass]="{'is-invalid': ticketOptionForm.get('Price')?.invalid && ticketOptionForm.get('Price')?.touched}"
              />
              <div *ngIf="ticketOptionForm.get('Price')?.invalid && ticketOptionForm.get('Price')?.touched" class="invalid-feedback">
                Price is required and should be >= 0
              </div>
            </div>

            <div class="form-group">
              <label for="description">Description</label>
              <textarea id="description" formControlName="Description" class="form-control"></textarea>
            </div>

            <div class="form-group">
              <label for="quota">Quota</label>
              <input
                type="number"
                id="quota"
                formControlName="Quota"
                class="form-control"
                [ngClass]="{'is-invalid': ticketOptionForm.get('Quota')?.invalid && ticketOptionForm.get('Quota')?.touched}"
              />
              <div *ngIf="ticketOptionForm.get('Quota')?.invalid && ticketOptionForm.get('Quota')?.touched" class="invalid-feedback">
                Quota is required and should be >= 0
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeTicketOptionModal()">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="addOrUpdateTicketOption()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
