import { Component } from '@angular/core';

@Component({
  selector: 'app-account-activity-report',
  templateUrl: './account-activity-report.component.html',
  styleUrl: './account-activity-report.component.css'
})
export class AccountActivityReportComponent {

}
