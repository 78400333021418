<div class="container">
  <div class="row">
    <div class="col-12 col-lg-4 offset-lg-4 auth-box">
      
       <div class="row">

        <div class="col-6 auth-title" [class.active]="authScreen === 'sign-in'" (click)="changeAuthScreen('sign-in')" *ngIf="authScreen === 'sign-in' || authScreen === 'sign-up'">Sign-in</div>
        <div class="col-6 auth-title" [class.active]="authScreen === 'sign-up'" (click)="changeAuthScreen('sign-up')" *ngIf="authScreen === 'sign-in' || authScreen === 'sign-up'">Sign-up</div>
        <div class="col-12 auth-title" [class.active]="authScreen === 'sign-up-confirm'" *ngIf="authScreen === 'sign-up-confirm'">Sign-up Confirm</div>
        <div class="col-12 auth-title" [class.active]="authScreen === 'reset-password'" *ngIf="authScreen === 'reset-password'"> reset password </div>
        <div class="col-12 auth-title" [class.active]="authScreen === 'reset-password-confirm'" *ngIf="authScreen === 'reset-password-confirm'"> reset password confirm </div>
        
        <div class="col-12 p-4" *ngIf="authScreen == 'sign-in'" aria-disabled="!isPageLoading">
          <label for="">Email</label>
          <input type="text" placeholder="email" [(ngModel)]="username" [disabled]="isPageLoading"/>
          <label for="">Password</label>
          <input type="password" placeholder="password" [(ngModel)]="password" [disabled]="isPageLoading"/>
          <button (click)="signin()" [disabled]="isPageLoading">
          <app-loading [show]="isPageLoading" [color]="loadingColor"></app-loading>  sign-in
          </button>
          <p (click)="changeAuthScreen('reset-password')">forgat password?</p>
        </div>
  
        <div class="col-12 p-4" *ngIf="authScreen == 'sign-up'">
          <label for="">name</label>
          <input type="text" [(ngModel)]="signUpForm.name" [disabled]="isPageLoading" autocomplete="off"/>
          <label for="">email</label>
          <input type="text" [(ngModel)]="signUpForm.email" [disabled]="isPageLoading"  autocomplete="off"/>
          <label for="">password</label>
          <input type="password" [(ngModel)]="signUpForm.password" [disabled]="isPageLoading" autocomplete="off"/>
          <label for="">phone number</label>
          <input type="string" [(ngModel)]="signUpForm.phoneNumber" [disabled]="isPageLoading" autocomplete="off"/>
          <label for="">birthdate</label>
          <input type="date" [(ngModel)]="signUpForm.birthdate" [disabled]="isPageLoading" autocomplete="off"/>
          <label for="">gender</label>
          <input type="text" [(ngModel)]="signUpForm.gender" [disabled]="isPageLoading" autocomplete="off"/>
          <button (click)="signup()" [disabled]="isPageLoading"> <app-loading [show]="isPageLoading"  [color]="loadingColor"></app-loading>  sign-up</button>
        </div>
    
        <div class="col-12 p-4" *ngIf="authScreen == 'sign-up-confirm'">
            <input type="text" [(ngModel)]="confirmCode" placeholder="verification code"  [disabled]="isPageLoading"/>
            <button (click)="signupConfirmation()" [disabled]="isPageLoading"> <app-loading [show]="isPageLoading"  [color]="loadingColor"></app-loading>  confirm </button>
            <p (click)="changeAuthScreen('sign-in')">go back</p>
        </div>

        <div class="col-12 p-4" *ngIf="authScreen == 'reset-password'">
          <input type="text" [(ngModel)]="resetpassword_email" placeholder="email address to reset password"  [disabled]="isPageLoading"/>
          <button (click)="resetpassword()" [disabled]="isPageLoading"> <app-loading [show]="isPageLoading"  [color]="loadingColor"></app-loading> send confirmation code </button>
          <p (click)="changeAuthScreen('sign-in')">go back</p>
        </div>

        <div class="col-12 p-4" *ngIf="authScreen == 'reset-password-confirm'">
          <label for="">verification code</label>
          <input type="text" [(ngModel)]="resetpassword_verificationcode" placeholder="verification code"  [disabled]="isPageLoading"/>
          <label for="">new password</label>
          <input type="text" [(ngModel)]="resetpassword_newpassword" placeholder="new password"  [disabled]="isPageLoading"/>
          <button (click)="resetpasswordconfirm()" [disabled]="isPageLoading"> <app-loading [show]="isPageLoading"  [color]="loadingColor"></app-loading> reset password </button>

          <p (click)="changeAuthScreen('sign-in')">go back</p>
        </div>

       </div>
    </div>     
  </div>
</div>