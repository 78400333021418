<div class="container mb-5 mt-1">
  <nav class="navbar navbar-expand-lg border-bottom">
    <div class="container-fluid justify-content-between"> <!-- Added justify-content-between -->
      <!-- Navbar brand/logo -->
      <a class="navbar-brand" routerLink="/">
        <img src="favicon.ico" alt="Icon" height="44" class="d-inline-block align-top" style="margin-right:10px;"> <!-- Replace 'path/to/your/icon.png' with the actual path to your icon -->
        <span class="fs-4">find events</span>
      </a>
      <!-- Hamburger button for mobile view -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navbar links -->
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ms-auto"> <!-- Added ms-auto to this ul -->
          <!-- Find Dance Events & Accounts -->
          <li class="nav-item" (click)="MenuClose()">
            <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="view-events">Find Events</a>
          </li>
          <li class="nav-item"  (click)="MenuClose()">
            <a class="nav-link"  routerLinkActive="active" routerLink="/accounts">Accounts</a>
          </li>

          <!-- Reports Dropdown -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Reports
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" routerLink="/reports/crawler"  (click)="MenuClose()">Crawler</a></li>
              <li><a class="dropdown-item" routerLink="/reports/clicks"  (click)="MenuClose()">Clicks</a></li>
              <li><a class="dropdown-item" routerLink="/reports/account"  (click)="MenuClose()">Account</a></li>
              <li><a class="dropdown-item" routerLink="/reports/event"  (click)="MenuClose()">Event</a></li>
              <li><a class="dropdown-item" routerLink="/reports/ticket-sell-report"  (click)="MenuClose()">Ticket Sell Report</a></li>
              <li><a class="dropdown-item" routerLink="/reports/payment-report"  (click)="MenuClose()">Payment Report</a></li>
              <li><a class="dropdown-item" routerLink="/reports/organizer"  (click)="MenuClose()">Organizer</a></li>
            </ul>
          </li>

          <!-- Event Calendar -->
          <li class="nav-item"  (click)="MenuClose()">
            <a class="nav-link"  routerLinkActive="active" routerLink="calandarview">Event Calendar</a>
          </li>

          <!-- Additional Links: Create Event, Login, Signup -->
          <li class="nav-item"  (click)="MenuClose()">
            <a class="nav-link"  routerLinkActive="active" routerLink="create-event">Create Event</a>
          </li>
          <li class="nav-item"  (click)="MenuClose()"  *ngIf="myAuthService.currentUser.isAuthenticated === false">
            <a class="nav-link"  routerLinkActive="active"routerLink="/auth">Login</a>
          </li>
          <li class="nav-item"  (click)="MenuClose()" *ngIf="myAuthService.currentUser.isAuthenticated">
            <a class="nav-link" (click)="onSingOutClick()">sign-out</a>
          </li>

          <li class="nav-item"  (click)="MenuClose()"  *ngIf="myAuthService.currentUser.isAuthenticated">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/profile']">
              {{ myAuthService.currentUser.name }}
            </a>
        </li>
          
        </ul>
      </div>
    </div>
  </nav>
</div>
