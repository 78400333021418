import { Component, OnInit,  } from '@angular/core';
import { MyAuthService } from '../../services/my-auth.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.css'
})
export class WelcomeComponent implements OnInit {

user: string | undefined = '';

  constructor(private myAuthService: MyAuthService) { }

  ngOnInit() {
    this.user = this.myAuthService.currentUser.name;
  }

}
