import { Component } from '@angular/core';

@Component({
  selector: 'app-give-us-feedback',
  templateUrl: './give-us-feedback.component.html',
  styleUrl: './give-us-feedback.component.css'
})
export class GiveUsFeedbackComponent {

}
