import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

export interface CreateEventTest {
  Title: string;
  Description?: string;
  YoutubeUrl?: string;
  WebSiteUrl?: string;
  TicketOptions?: TicketOption[];
}

export interface TicketOption {
  Name: string;
  Price: number;
  Description?: string;
  Quota: number;
}

@Component({
  selector: 'app-create-event-test',
  templateUrl: './create-event-test.component.html',
  styleUrls: ['./create-event-test.component.css']
})
export class CreateEventTestComponent implements OnInit {
  createEventForm: FormGroup;
  ticketOptionForm: FormGroup;
  isTicketOptionModalVisible: boolean = false;
  currentEditIndex: number | null = null;

  constructor(private fb: FormBuilder) {
    this.createEventForm = this.fb.group({
      Title: ['', Validators.required],
      Description: [''],
      YoutubeUrl: [''],
      WebSiteUrl: [''],
      TicketOptions: this.fb.array([])
    });

    this.ticketOptionForm = this.fb.group({
      Name: ['', Validators.required],
      Price: ['', [Validators.required, Validators.min(0)]],
      Description: [''],
      Quota: ['', [Validators.required, Validators.min(0)]]
    });
  }

  ngOnInit(): void {}

  get ticketOptions(): FormArray {
    return this.createEventForm.get('TicketOptions') as FormArray;
  }

  openTicketOptionModal(index: number | null = null): void {
    this.isTicketOptionModalVisible = true;
    this.currentEditIndex = index;

    if (index !== null) {
      const ticketOption = this.ticketOptions.at(index).value;
      this.ticketOptionForm.setValue(ticketOption);
    } else {
      this.ticketOptionForm.reset();
    }

    this.ticketOptionForm.markAsUntouched();
  }

  closeTicketOptionModal(): void {
    this.isTicketOptionModalVisible = false;
    this.ticketOptionForm.reset();
  }

  addOrUpdateTicketOption(): void {
    if (this.ticketOptionForm.invalid) {
      this.ticketOptionForm.markAllAsTouched();
      return;
    }

    if (this.currentEditIndex === null) {
      this.ticketOptions.push(this.fb.group(this.ticketOptionForm.value));
    } else {
      this.ticketOptions.at(this.currentEditIndex).patchValue(this.ticketOptionForm.value);
    }

    this.closeTicketOptionModal();
  }

  removeTicketOption(index: number): void {
    this.ticketOptions.removeAt(index);
  }

  submitForm(): void {
    if (this.createEventForm.invalid) {
      this.createEventForm.markAllAsTouched();
      return;
    }

    console.log('Form Submitted', this.createEventForm.value);
  }
}
