import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { WelcomeComponent } from './components/welcome/welcome.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthComponent } from './components/auth/auth.component';
import { ViewEventsComponent } from './components/event/view-events/view-events.component';
import { TermsOfUseComponent } from './components/footer/terms-of-use/terms-of-use.component';
import { PrivacyComponent } from './components/footer/privacy/privacy.component';
import { GiveUsFeedbackComponent } from './components/footer/give-us-feedback/give-us-feedback.component';
import { ContactUsComponent } from './components/footer/contact-us/contact-us.component';
import { AboutUsComponent } from './components/footer/about-us/about-us.component';
import { EventDetailsComponent } from './components/event/view-events/event-details/event-details.component';
import { UpdateEventComponent } from './components/organizer-ops/update-event/update-event.component';
import { CreateEventTestComponent } from './components/organizer-ops/create-event-test/create-event-test.component';
import { CreateEventOldComponent } from './components/organizer-ops/create-event-old/create-event-old.component';
import { CreateEventComponent } from './components/organizer-ops/create-event/create-event.component';
import { GoogleMapTestComponent } from './components/organizer-ops/google-map-test/google-map-test.component';
import { SearchOnMapComponent } from './components/organizer-ops/search-on-map/search-on-map.component';
import { ElementTestComponent } from './components/organizer-ops/element-test/element-test.component';

const routes: Routes = [
  // public
  { path:'', component:WelcomeComponent },
  { path:'welcome', component:WelcomeComponent },
  { path:'about', component:AboutUsComponent },
  { path:'contact-us', component:ContactUsComponent },
  { path:'give-us-feedback', component:GiveUsFeedbackComponent },
  { path:'privacy', component:PrivacyComponent },
  { path:'terms-of-use', component:TermsOfUseComponent },
  { path:'view-events', component:ViewEventsComponent },
  { path:'create-event', component:CreateEventComponent },
  { path:'create-event-old', component:CreateEventOldComponent },
  { path:'element-test', component:ElementTestComponent },
  { path:'create-event-test', component:CreateEventTestComponent },
  { path:'search-on-map', component:SearchOnMapComponent },
  { path:'google-map-test', component:GoogleMapTestComponent },
  { path:'update-event/:id', component:UpdateEventComponent },
  { path:'event-details/:id', component:EventDetailsComponent },
  { path:'auth', component:AuthComponent },
  { path:'not-found', component:NotFoundComponent },


  { path:'**', redirectTo:'not-found' } // this must be last line always
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


 }
