import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Amplify } from 'aws-amplify';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';

import awsconfig from '../aws-exports';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';
import { AuthComponent } from './components/auth/auth.component';
import { ListviewComponent } from './components/event/view-events/listview/listview.component';
import { CardviewComponent } from './components/event/view-events/cardview/cardview.component';
import { CalendarviewComponent } from './components/event/view-events/calendarview/calendarview.component';
import { MapviewComponent } from './components/event/view-events/mapview/mapview.component';
import { MixedviewComponent } from './components/event/view-events/mixedview/mixedview.component';
import { MyAuthService } from './services/my-auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from './services/alert.service';
import { LoadingComponent } from './components/loading/loading.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CardComponent } from './components/event/view-events/cardview/card/card.component';
import { ViewEventsComponent } from './components/event/view-events/view-events.component';
import { CreateEventOldComponent } from './components/organizer-ops/create-event-old/create-event-old.component';
import { UpdateEventComponent } from './components/organizer-ops/update-event/update-event.component';
import { FilterComponent } from './components/event/view-events/filter/filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TermsOfUseComponent } from './components/footer/terms-of-use/terms-of-use.component';
import { PrivacyComponent } from './components/footer/privacy/privacy.component';
import { GiveUsFeedbackComponent } from './components/footer/give-us-feedback/give-us-feedback.component';
import { ContactUsComponent } from './components/footer/contact-us/contact-us.component';
import { AboutUsComponent } from './components/footer/about-us/about-us.component';
import { TheBiggestEventSearchEngineComponent } from './components/funnels/the-biggest-event-search-engine/the-biggest-event-search-engine.component';
import { SellYourTicketsComponent } from './components/funnels/sell-your-tickets/sell-your-tickets.component';
import { EventDetailsComponent } from './components/event/view-events/event-details/event-details.component';
import { TicketSellingReportComponent } from './components/report/admin-reports/ticket-selling-report/ticket-selling-report.component';
import { CrawlerReportComponent } from './components/report/admin-reports/crawler-report/crawler-report.component';
import { AccountActivityReportComponent } from './components/report/admin-reports/account-activity-report/account-activity-report.component';
import { VisitorReportComponent } from './components/report/admin-reports/visitor-report/visitor-report.component';
import { PaymentReportComponent } from './components/report/admin-reports/payment-report/payment-report.component';
import { CreateEventTestComponent } from './components/organizer-ops/create-event-test/create-event-test.component';
import { CreateEventComponent } from './components/organizer-ops/create-event/create-event.component';
import { GoogleMapTestComponent } from './components/organizer-ops/google-map-test/google-map-test.component';
import { SearchOnMapComponent } from './components/organizer-ops/search-on-map/search-on-map.component';
import { ElementTestComponent } from './components/organizer-ops/element-test/element-test.component';
import { InputComponent } from './components/global/input/input.component';

Amplify.configure(awsconfig);


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MenuComponent,
    AuthComponent,
    ListviewComponent,
    CardviewComponent,
    CalendarviewComponent,
    MapviewComponent,
    MixedviewComponent,
    LoadingComponent,
    CardComponent,
    ViewEventsComponent,
    CreateEventOldComponent,
    UpdateEventComponent,
    FilterComponent,
    GiveUsFeedbackComponent,
    ContactUsComponent,
    AboutUsComponent,
    TheBiggestEventSearchEngineComponent,
    SellYourTicketsComponent,
    EventDetailsComponent,
    CrawlerReportComponent,
    AccountActivityReportComponent,
    VisitorReportComponent,
    PaymentReportComponent,
    CreateEventTestComponent,
    CreateEventComponent,
    GoogleMapTestComponent,
    SearchOnMapComponent,
    ElementTestComponent,
    InputComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AmplifyAuthenticatorModule,
    NgSelectModule,
    FormsModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [MyAuthService, AlertService],
  bootstrap: [AppComponent]
})
export class AppModule { }
