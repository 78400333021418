<div><input #pacInput class="map-search-box" type="text" placeholder="Enter a location or click from map"/></div>
<div #mapContainer style="width: 100%; height: 450px;" class="mb-2"></div>


<form [formGroup]="createAddressForm">

  <!-- Country -->
  <div class="form-group">
    <label for="CountryName">Country</label>
    <input id="CountryName" formControlName="CountryName" class="form-control" readonly
    [ngClass]="{'is-invalid': createAddressForm.get('CountryName')?.invalid && createAddressForm.get('CountryName')?.touched}" />
    <div *ngIf="createAddressForm.get('CountryName')?.invalid && createAddressForm.get('CountryName')?.touched" class="invalid-feedback">
        Country is required, please pick an address from the map
    </div>
  </div>

  <!-- State/Province/Region -->
  <div class="form-group">
    <label for="StateProvinceRegion">State/Province/Region</label>
    <input id="StateProvinceRegion" formControlName="StateProvinceRegion" class="form-control" 
    [ngClass]="{'is-invalid': createAddressForm.get('StateProvinceRegion')?.invalid && createAddressForm.get('StateProvinceRegion')?.touched}"
    />
    <div *ngIf="createAddressForm.get('StateProvinceRegion')?.invalid && createAddressForm.get('StateProvinceRegion')?.touched" class="invalid-feedback">
      State/Province/Region is required
    </div>
  </div>

  <!-- City/Town -->
  <div class="form-group">
    <label for="CityTown">City/Town</label>
    <input id="CityTown" formControlName="CityTown" class="form-control" 
    [ngClass]="{'is-invalid': createAddressForm.get('CityTown')?.invalid && createAddressForm.get('CityTown')?.touched}"/>
    <div *ngIf="createAddressForm.get('CityTown')?.invalid && createAddressForm.get('CityTown')?.touched" class="invalid-feedback">City/Town is required</div>
  </div>

  <!-- StreetBuildingEtc -->
  <div class="form-group">
    <label for="StreetBuildingEtc">Street/Building, etc</label>
    <input id="StreetBuildingEtc" formControlName="StreetBuildingEtc" class="form-control" 
    [ngClass]="{'is-invalid': createAddressForm.get('StreetBuildingEtc')?.invalid && createAddressForm.get('StreetBuildingEtc')?.touched}"/>
    <div *ngIf="createAddressForm.get('StreetBuildingEtc')?.invalid && createAddressForm.get('StreetBuildingEtc')?.touched" class="invalid-feedback">StreetBuildingEtc is required</div>
  </div>

  <!-- ZIP Code -->
  <div class="form-group">
    <label for="PostalZIPCode">Postal/ZIP Code</label>
    <input id="PostalZIPCode" formControlName="PostalZIPCode" class="form-control" 
    [ngClass]="{'is-invalid': createAddressForm.get('PostalZIPCode')?.invalid && createAddressForm.get('PostalZIPCode')?.touched}"/>
    <div *ngIf="createAddressForm.get('PostalZIPCode')?.invalid && createAddressForm.get('PostalZIPCode')?.touched" class="invalid-feedback">Postal/ZIP is required</div>
  </div>

  <!-- Place Name -->
  <div class="form-group">
    <label for="PlaceName">Place Name</label>
    <input id="PlaceName" formControlName="PlaceName" class="form-control"/>
  </div>

</form>