import { Component, Input } from '@angular/core';
import { EventCardItem } from '../../../../../models/event';
import { Router } from '@angular/router';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.css'
})
export class CardComponent {
  constructor(private router: Router) { }
  @Input() card: EventCardItem = {} as EventCardItem;

  openDetails(id: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/event-details', id])
    );
    window.open(url, '_blank');
  }
}