import { Component } from '@angular/core';

@Component({
  selector: 'app-crawler-report',
  templateUrl: './crawler-report.component.html',
  styleUrl: './crawler-report.component.css'
})
export class CrawlerReportComponent {

  crawlingReport = [
    {
        source:'goanddance',
        date:'2024-01-01',
        count:20            
    },
    {
        source:'alleventsin',
        date:'2024-01-01',
        count:35            
    },
    {
        source:'instagram',
        date:'2024-01-01',
        count:40            
    }];

}
