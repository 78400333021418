import { Component, AfterViewInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var google: any;

@Component({
  selector: 'app-search-on-map',
  templateUrl: './search-on-map.component.html',
  styleUrls: ['./search-on-map.component.css']
})
export class SearchOnMapComponent implements AfterViewInit {
  @ViewChild('mapContainer') mapContainer!: ElementRef;
  @ViewChild('pacInput') pacInput!: ElementRef;

  createAddressForm: FormGroup;

  map!: google.maps.Map;
  marker!: google.maps.Marker;
  infowindow!: google.maps.InfoWindow;
  geocoder!: google.maps.Geocoder;

  constructor(private ngZone: NgZone, private fb: FormBuilder) {
    this.createAddressForm = this.fb.group({
      CountryName: ['', Validators.required],
      StateProvinceRegion: ['', Validators.required],
      CityTown: [''],      
      StreetBuildingEtc: ['', Validators.required],      
      PlaceName: [''],
      PostalZIPCode: ['', Validators.required],
    });
  }



  ngAfterViewInit(): void {
    const center = { lat: 51, lng: 8 };
    this.map = new google.maps.Map(this.mapContainer.nativeElement, {
      center: center,
      zoom: 6,
      mapTypeControl: false,
      streetViewControl: false,
    });

    const autocomplete = new google.maps.places.Autocomplete(this.pacInput.nativeElement, {
      fields: ["place_id", "geometry", "formatted_address", "name", "address_components"],
    });

    autocomplete.bindTo('bounds', this.map);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.pacInput.nativeElement);

    this.infowindow = new google.maps.InfoWindow();
    this.marker = new google.maps.Marker({
      map: this.map,
      draggable: true,
      position: center
    });
    this.geocoder = new google.maps.Geocoder();

    this.marker.addListener("click", () => {
      this.infowindow.open(this.map, this.marker);
    });

    this.map.addListener("click", (event: google.maps.MapMouseEvent) => {
      this.ngZone.run(() => {
        this.setMarkerPosition(event.latLng!);
      });
    });

    this.marker.addListener("dragend", (event: google.maps.MapMouseEvent) => {
      this.ngZone.run(() => {
        this.setMarkerPosition(event.latLng!);
      });
    });

    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          this.setMarkerPosition(place.geometry.location, place);
          this.map.setZoom(17);  // Set zoom level to 17 after a place is selected
        } else {
          this.infowindow.setContent(`<div>No location found.</div>`);
          this.infowindow.open(this.map, this.marker);
          this.map.setZoom(6);  // Optionally, reset to a broader zoom if no location is found
        }
      });
    });
    
  }

  private setMarkerPosition(location: google.maps.LatLng, place?: google.maps.places.PlaceResult) {
    this.marker.setPosition(location);
    this.map.panTo(location);

    if (place) {
      this.setInfoWindowContent(place);
    } else {
      this.geocoder.geocode({ location: location }, (results, status) => {
        if (status === 'OK' && results && results[0]) {
          this.ngZone.run(() => {
            this.setInfoWindowContent(results[0]);
          });
        } else {
          this.infowindow.setContent('<div>No results found</div>');
          this.infowindow.open(this.map, this.marker);
        }
      });
    }
  }

  

  private setInfoWindowContent(place: google.maps.places.PlaceResult | google.maps.GeocoderResult) {
    const lat = this.marker.getPosition()!.lat();
    const lng = this.marker.getPosition()!.lng();
  
    let addressComponents = place.address_components;
    let city = '', state = '', zipcode = '', countryCode = '', countryName = '', placeName = '', street = '', streetNumber = '';
  
    // Extract place name and ID if available
    if ('name' in place && place.name) {
      placeName = place.name;
    }
  
    console.log(addressComponents);
    
    if (addressComponents) {
      addressComponents.forEach(component => {
          const types = component.types;
          if (types.includes('country')) {
              countryCode = component.short_name;  
              countryName = component.long_name; 
          }
          if (types.includes('locality')) {
              city = component.long_name;
          }
          if (types.includes('administrative_area_level_1')) {
              state = component.short_name;
          }
          if (types.includes('postal_code')) {
              zipcode = component.short_name;
          }
          if (types.includes('route')) {
              street = component.long_name;
          }
          if (types.includes('street_number')) {
              streetNumber = component.long_name;
          }
          // Check for administrative_area_level_2 if city is still empty
          if (types.includes('administrative_area_level_2') && !city) {
              city = component.long_name;
          }
      });
  }
  
  
    const streetBuildingEtc = `${streetNumber} ${street}`.trim(); // Combine street number and street name
  
    // Update form with the extracted components
    this.createAddressForm.patchValue({
      CountryName: countryName,
      StateProvinceRegion: state,
      CityTown: city,
      StreetBuildingEtc: streetBuildingEtc,  // Update this field specifically with the street info
      PlaceName: placeName,
      PostalZIPCode: zipcode
    });
  
    const contentElement = document.createElement('div');
    contentElement.className="container-fluid";
    contentElement.innerHTML = `
      <div class="row">
        <div class="col-12" style='font-size:larger'> ${placeName} ${streetBuildingEtc} ${city} ${state} ${zipcode} ${countryCode} </div>
        <div class="col-12 text-center mt-2"><button id="continueButton" onClick="window.scrollBy(0, 280);" class="btn btn-primary">Continue with this address</button></div>
      </div>`;
  
    this.infowindow.setContent(contentElement);
    this.infowindow.open(this.map, this.marker);
  
    // Manually add event listener to the button
    const continueButton = contentElement.querySelector('#continueButton');
    if (continueButton) {
      continueButton.addEventListener('click', () => {
        this.ngZone.run(() => {
          this.closeInfoWindow();
        });
      });
    } else {
      console.error("The continue button was not found in the InfoWindow content.");
    }
  }
  

  // Method to close the marker's InfoWindow
closeInfoWindow(): void {
  if (this.infowindow) {
    window.scrollBy(0, 280);
    this.infowindow.close();
    this.marker.setIcon('');

    //const icon = { url: 'assets/images/marker-ok.png',  scaledSize: new google.maps.Size(64, 64)};
    //this.marker.setIcon(icon);

  }
}

}