import { Component } from '@angular/core';

@Component({
  selector: 'app-mixedview',
  templateUrl: './mixedview.component.html',
  styleUrl: './mixedview.component.css'
})
export class MixedviewComponent {

}
