import { Component } from '@angular/core';
import { AlertService } from './services/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'frontend';
  alertService: AlertService;

 constructor(alertService: AlertService) {
   this.alertService = alertService;
  }

  CloseAlert()
  {
    this.alertService.visible = false;
  }

}
