  
  interface AppInfo {
    AppName: string;
    CompanyName: string;
    Domain: string;
    Motto: string;
    Description: string;
    Address: string;
    AddressUrl: string;
    TelegramUrl: string;
    TelegramCaption: string;
    InstagramUrl: string;
    InstagramCaption: string;
    email: string;
    ownership_declareation: string;
  }
  
  
export const AppInfo: AppInfo = {
  AppName: 'event app',
  Domain: 'eventapp.com',
  Motto: 'find social events wherever you go, anytime!',
  Description: 'You can find social events, conferances, parties, festivals, any type of events around you! If you are an organizer or a trainer, or an owner of a event place you can create events here for free. Find all these social events in your area or around the world!',
  Address: 'Konyaalti, Antalya, Turkey',
  AddressUrl: 'https://www.google.com/maps/@36.863482,30.6418355,17.8z',
  TelegramUrl: 'https://telegram.me/alicakil78',
  TelegramCaption: 'alicakil78',
  InstagramUrl: 'https://www.instagram.com/evntapp/',
  InstagramCaption: 'eventapp',
  email: 'info@eventapp.com',
  CompanyName: 'Ali Çakıl',
  ownership_declareation: 'This application is owned and operated by solo proprietorship of Ali Çakıl.',
};
  
  export enum EventStatus {
    Draft,
    Published,
    Cancelled,
    OnHold,
    Completed,
  }

  export enum EventWhen {    
    Today,
    Tomorrow,
    ThisWeek,
    ThisMonth,
    Custom,
    Anytime,
  }