<div class="text-center" *ngIf="isLoading"><div class="spinner-border text-info" role="status"></div></div>

<div class="container">
    <div class="row">
        <div class="col-lg-8 offset-lg-2">
            <div class="event-details-card">
                <div class="row">
                    <div class="col-md-6">
                        <img *ngIf="response?.CoverImgURL" [src]="response?.CoverImgURL" alt="Event Cover Image" class="event-cover-img">
                    </div>
                    <div class="col-md-6">
                        <h2 class="event-title">{{ response?.Title }}</h2>
                        <div class="event-meta">
                            <p><strong>Date:</strong> {{ response?.StartsAt | date: 'mediumDate' }}</p>
                            <p><strong>Time:</strong> {{ response?.StartsAt | date: 'shortTime' }} - {{ response?.EndsAt | date: 'shortTime' }}</p>
                            <p><strong>Location:</strong> {{ response?.City }}, {{ response?.CountryName }}</p>
                            <p *ngIf="response?.Address"><strong>Address:</strong> {{ response?.Address }}</p>
                        </div>
                        <div class="event-description">
                            <p>{{ response?.Description }}</p>
                        </div>
                        <div id="categories">
                            categories:
                            <hr>
                            @for (category of response?.Categories; track $index) 
                            {
                                <span class="alert alert-info m-2 p-2" role="alert">{{ category }}</span>
                            }
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h3 class="section-title">Ticket Options</h3>
                        <ul class="ticket-options-list">
                            <li *ngFor="let option of response?.Options">
                                <strong>{{ option.Name }}</strong> - {{ option.Description }} | Price: {{ option.Price }} | Quota: {{ option.Quota }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="countdown">
                            <p class="countdown-text">Time remaining until the event starts:</p>
                            <p class="countdown-timer">{{ countdownTimer }}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="event-actions">
                            <a href="#" class="btn btn-primary">Buy Ticket</a>
                        </div>
                    </div>
                    <div class="col-12">
                        <div id="map" class="mt-3" style="height: 500px; width: 100%;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>