import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  type:string = 'danger';
  title:string = '';
  message:string = '';
  visible:boolean = false;

  constructor() { }

  alert(i: {type:string, message:string})
  {
    this.type = i.type;
    this.message = i.message;
    this.visible = true;
    window.scrollTo(0, 0);
  }

 

}
