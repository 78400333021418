import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CreateEvent } from '../models/create-event';

@Injectable({providedIn: 'root'})

export class OrganizerOpsService {

  private apiUrl = environment.apiUrl;
  
  constructor(private http: HttpClient) {}

  getSignedUrlToUploadCoverImg(): Observable<any> {
    return this.http.post(`${this.apiUrl}app/organizer-ops?function=s3-signed-url-for-cover-img-upload`, null);
  }

  uploadCoverImageToS3(signedUrl: string, file: File): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': file.type });
    return this.http.put(signedUrl, file, { headers, responseType: 'text' });
  }

  createEvent(eventData: CreateEvent): Observable<any> {
    return this.http.post(`${this.apiUrl}app/organizer-ops?function=create-event`, eventData);
  }

}