<app-menu></app-menu>

<div *ngIf="alertService.visible" class="container" style="margin-top: -25px;">
    <div class="row">
      <div class="col">
        <div class="alert alert-{{alertService.type}} alert-dismissible fade show" role="alert">

            <i class="bi bi-info-circle" style="margin-right: 5px; font-size: 20px;" *ngIf="alertService.type === 'info'"></i>
            <i class="bi bi-x-circle" style="margin-right: 5px; font-size: 20px;" *ngIf="alertService.type === 'danger'"></i>

            <strong *ngIf="alertService.type">{{ alertService.title | titlecase }} </strong>  {{ alertService.message }}
          <button type="button" class="btn-close" aria-label="Close"(click)="CloseAlert()"></button>
        </div>
      </div>
    </div>
  </div>

<router-outlet></router-outlet>
<app-footer></app-footer>