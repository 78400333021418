import { Component } from '@angular/core';
import { MyAuthService } from '../../services/my-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css'
})
export class MenuComponent {

  constructor(public myAuthService: MyAuthService, public router: Router) { }

  onSingOutClick() {  
    this.myAuthService.signout();
    this.router.navigate(['/auth']);   
  }

  MenuClose() 
  {
    if (window.innerWidth > 992) return;

    const navbarToggler = document.querySelector('.navbar-toggler'); 
    if (navbarToggler) 
    {
      navbarToggler.dispatchEvent(new Event('click'));
    } 
    else 
    {
      alert('navbarToggler is null');
    }
  }

}