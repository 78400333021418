import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-google-map-test',
  templateUrl: './google-map-test.component.html',
  styleUrl: './google-map-test.component.css'
})
export class GoogleMapTestComponent implements OnInit {
  @ViewChild('shipAddress') address1FieldRef!: ElementRef<HTMLInputElement>;
  address2Field!: HTMLInputElement;
  postalField!: HTMLInputElement;

  autocomplete!: google.maps.places.Autocomplete;

  address1 = '';
  address2 = '';
  postcode = '';
  locality = '';
  state = '';
  country = '';

  ngOnInit(): void {
    this.initAutocomplete();
  }

  initAutocomplete() {
    setTimeout(() => {
      this.address1FieldRef.nativeElement.focus();
      this.address2Field = document.querySelector("#address2") as HTMLInputElement;
      this.postalField = document.querySelector("#postcode") as HTMLInputElement;

      // Create the autocomplete object, restricting the search predictions to addresses in the US and Canada.
      this.autocomplete = new google.maps.places.Autocomplete(
        this.address1FieldRef.nativeElement,
        {
          componentRestrictions: { country: ['tr'] },
          fields: ['address_components', 'geometry', 'place_id', 'name']
        } 
      );

      // When the user selects an address from the drop-down, populate the address fields in the form.
      this.autocomplete.addListener('place_changed', this.fillInAddress.bind(this));
    }, 1000);
  }

  fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = this.autocomplete.getPlace();
    let address1 = '';
    let postcode = '';

    console.log('place', place);

    // Get each component of the address from the place details,
    // and then fill in the corresponding field on the form.
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case 'route': {
          address1 += component.short_name;
          break;
        }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case 'locality':
          this.locality = component.long_name;
          break;

        case 'administrative_area_level_1': {
          this.state = component.short_name;
          break;
        }

        case 'country':
          this.country = component.long_name;
          break;
      }
    }

    this.address1 = address1;
    this.postcode = postcode;

    // After filling the form with address components from the Autocomplete prediction,
    // set cursor focus on the second address line to encourage entry of sub-premise information.
    this.address2Field.focus();
  }
}
