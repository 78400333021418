import { Component, OnInit, ViewChild   } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MyAuthService, SignUpForm } from '../../services/my-auth.service';
import { AlertService } from '../../services/alert.service';
import { Router } from '@angular/router'; // Import the Router

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.css'
})


export class AuthComponent implements OnInit {

  alertService: AlertService;
  isPageLoading:boolean = false;
  loadingColor:string = "#646464";

  authScreen:string = 'sign-in'; // 'sign-in' | 'sign-up' | 'sign-up-confirm' | 'reset-password | 'reset-password-confirm'
  signUpForm: SignUpForm = { name: '', email: '', password: '', phoneNumber: '', birthdate: '', gender: ''};
  
  // for signin
  username:string = '';
  password:string = '';
  
  // for signup confirm
  confirmEmail:string=''
  confirmCode:string='';

    // for reset password
    resetpassword_email:string=''
    resetpassword_verificationcode:string='';
    resetpassword_newpassword:string='';

  constructor(public myAuthService: MyAuthService, alertService: AlertService, public router: Router ) 
  {
    this.alertService = alertService;
  }

  ngOnInit() {
    // this.myAuthService.fetchUserAttributes();
  }

 async signin() {

    this.isPageLoading = true;

    await this.myAuthService.signin(this.username, this.password)
      .then((message) => {
        
        this.alertService.alert({type: 'success', message: message});
        console.log(message); // You can remove this line        
        this.router.navigate(['/welcome']);
        this.isPageLoading = false;
      })
      .catch((errorMessage) => {
        // Handle error
        this.alertService.alert({type: 'danger', message: errorMessage});
        this.isPageLoading = false;
      });
  }

  signout() 
  {   
    this.myAuthService.signout();
  }

  async signup() { 

    this.isPageLoading = true;

    // validation
    if(this.signUpForm.name == '' || this.signUpForm.email == '' || this.signUpForm.password == '' || this.signUpForm.phoneNumber == '' || this.signUpForm.birthdate == '')
    {
      this.alertService.alert({type:'info', message:'Please fill all fields!'});
      this.isPageLoading = false;
      return; // Exit the function if validation fails
    }

    // validate phone number
    //if (!/^\d{10}$/.test(this.signUpForm.phoneNumber)) {
    //  this.alertService.alert({type:'danger', message:'Please enter a valid phone number'});
    //  this.isPageLoading = false;
    //  return; // Exit the function if validation fails
   // }

   this.signUpForm.email = this.signUpForm.email.toLowerCase().trim();
   this.signUpForm.name = this.signUpForm.name.trim();

    this.myAuthService.signup(this.signUpForm)
      .then((response) => {
        console.log('Signup successful', response);
        if (response === "CONFIRM_SIGN_UP") {
          this.authScreen = 'sign-up-confirm';
          this.confirmEmail = this.signUpForm.email.trim();
          console.log(`Please confirm sign up with code sent to ${this.signUpForm.email}`);
          this.alertService.alert({type:'info', message:'Please confirm sign up with code sent to your email'});
          this.isPageLoading = false;
        }
        else {
          
          this.alertService.alert({type:'danger', message: response});
          console.log('auth.component: undefined response from signup!');
          console.log('auth.component: signUpStep', response);
          this.isPageLoading = false;
        }
      })
      .catch((error) => {
        this.alertService.alert({type:'danger', message: error});
        this.isPageLoading = false;
      });
  }

  async signupConfirmation() 
  {
    this.isPageLoading = true;
    const signUpConfirmAttributes = {username: this.confirmEmail,confirmationCode: this.confirmCode};

    await this.myAuthService.signupConfirmation(signUpConfirmAttributes)
    .then((message) => 
    {       
      console.log('signupConfirmation then triggered:', message);
      this.alertService.alert({type: 'success', message: message});
       // here login the user automatically (if not logged int in already in cognito, to be checked)
      //this.router.navigate(['/welcome']);
      this.isPageLoading = false;
    })
    .catch((error) => {
      console.log('signupConfirmation catch triggered:', error);
      this.alertService.alert({type: 'danger', message: error});
      this.isPageLoading = false;
    });

    console.log('end of signupConfirmation()');
  }

  // reseting password: step 1
  async resetpassword() { 

    this.isPageLoading = true;
   this.resetpassword_email = this.resetpassword_email.toLowerCase().trim();

    this.myAuthService.resetpassword(this.resetpassword_email)
      .then((response) => {
        console.log('resetpassword initial step succesful', response);

        if (response === "DONE") {
          this.alertService.alert({type:'info', message:'Password Reset Completed!'});
          this.isPageLoading = false;
        }
        else {
          // Next step (show confirmation code input field)
          this.isPageLoading = false;
          this.authScreen = 'reset-password-confirm';
        }
      })
      .catch((error) => {
        this.alertService.alert({type:'danger', message: error});
        this.isPageLoading = false;
      });
  }

// reseting password: step 2
  async resetpasswordconfirm()   { 

    this.isPageLoading = true;
    this.myAuthService.confirmresetpassword({username: this.resetpassword_email, confirmationCode: this.resetpassword_verificationcode, newPassword: this.resetpassword_newpassword})
      .then((response) => {
        console.log('confirmresetpassword then response', response);

        if (response === "DONE") {
          this.alertService.alert({type:'info', message:'Password Reset Completed!'});
          this.isPageLoading = false;
          // here login the user automatically
        }
        else {
          this.isPageLoading = false;
          this.alertService.alert({type:'danger', message: response});
        }
      })
      .catch((error) => {
        this.alertService.alert({type:'danger', message: error});
        this.isPageLoading = false;
      });
  }

  changeAuthScreen(screen:string)
  {
    this.authScreen = screen;
  }

}


