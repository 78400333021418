import { AfterViewInit, Component, OnInit,  } from '@angular/core';
import { Subscription } from 'rxjs';
import { ViewEventService } from '../../../../services/view-event.service';
import { EventMapItem, MapData } from '../../../../models/event';

@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styleUrl: './mapview.component.css'
})


export class MapviewComponent implements AfterViewInit {
  
  private subscriptions: Subscription = new Subscription();
  isLoading = true;
  errorMessage = '';
  response: MapData<EventMapItem> | null = null;

  constructor(public viewEventService: ViewEventService) { }

  ngAfterViewInit()
  {
    this.subscriptions.add(this.viewEventService.filterChanged$.subscribe(_ => {  this.loadEvents();  }));
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


  loadEvents() {
    this.isLoading = true;
    this.viewEventService.getMapMarks(-90,90,-180,180)  // initially show all the earth
    .subscribe({
        next: (p) => {
            console.log("getEventCards Response received!");           
            this.response = p;
            console.log(this.response);
            this.isLoading = false;

            this.initMap();
        },
        error: (error) => {
            this.errorMessage = error;
            this.isLoading = false;
        },
    });
}



  async initMap() {
    // Request needed libraries.
    console.log("initMap running...");
    const { Map  } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
      const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    
      const map = new Map(document.getElementById('map') as HTMLElement, { center: { lat: 37.4239163, lng: -122.0947209 }, zoom: 14, mapId: '4504f8b37365c3d0', });  
      
      
      this.response?.Data.forEach((item) => { 

        // marker styles
        const pin = new PinElement({background: '#1E90FF', borderColor: 'white', glyphColor: 'white'});      

        // create marker
        const marker = new AdvancedMarkerElement({ map, position: { lat: item.Lat, lng: item.Lng }, title: item.Title, content: pin.element });

        // create info window
        const infoWindow = new google.maps.InfoWindow({
          content: `<div class='text-center'>
                      <h6 style='margin-bottom:3px;'>${item.Title}</h6>
                      <br/><img src='${item.CoverImgURL}' style='width=100%'/>
                      <br/>${item.TicketPrice}
                      <br><button onclick="window.open('/event-details/${item.Id}', '_blank')" style='background-color: dodgerblue; color: white; border: none; font-size:16px; padding: 10px; margin-top:5px !important; border-radius:5px;'>View Details</button>
                    </div>`
        });
        
        // add listener to marker
        marker.addListener('click', () => {infoWindow.open(map, marker);});
      });
   

      var bounds = new google.maps.LatLngBounds(
        { lat: this.response?.LatMin ?? 0, lng: this.response?.LngMin ?? 0 }, // Southwest corner of the bounds
        { lat: this.response?.LatMax ?? 0, lng: this.response?.LngMax ?? 0 }  // Northeast corner of the bounds
    );

      map.fitBounds(bounds);


      map.addListener("center_changed", debounce(() => {        
        const bounds = map!.getBounds();
        if (bounds) {
            const ne = bounds.getNorthEast(); // Northeast corner
            const sw = bounds.getSouthWest(); // Southwest corner
            // Print the bounds to the console
            console.log(`LatMax: ${ne.lat()}, LatMin: ${sw.lat()}, LngMax: ${ne.lng()}, LngMin: ${sw.lng()}`);
        }
    }, 250)); // Adjust the delay in milliseconds
    

    function debounce(func: (...args: any[]) => void, wait: number) {
      let timeout: number | undefined;
      return (...args: any[]) => {
          clearTimeout(timeout);
          timeout = window.setTimeout(() => func(...args), wait);
      };
  }
  
  }
  

}