<div class="container">
    <form [formGroup]="createEventForm" (ngSubmit)="submitForm()">

      <div class="group-title"> <i class="bi bi-plus-circle"></i> Create Event </div>

      <div class="row">
        <div class="col-12 col-lg-7 pt-4">

          <!-- Title  -->
          <app-input 
            label="Title" 
            formControlName="Title" 
            [formGroup]="createEventForm" 
            [isRequired]="true"
            validationMessage="Event title is required">
          </app-input>
    

          <!-- Event Type  -->
          <div class="form-group">
            <label for="type" class="form-label">Event Type</label>
            <select id="type" formControlName="Type" class="form-select">
              <option *ngFor="let type of eventTypes" [value]="type">{{ type }}</option>
            </select>
            <span *ngIf="createEventForm.get('Type')?.invalid && createEventForm.get('Type')?.touched" class="text-danger">Event Type is required</span>
          </div>
           
          <!-- Category  -->
          <label for="category">Category</label>
          <ng-select id="category"
              [items]="viewEventService.filterData().Categories"
              bindLabel="Name"
              bindValue="Id"
              placeholder="Select a category"
              groupBy="Parent"
              [multiple]="true"
              formControlName="Categories"
              [loading]="viewEventService.filterDataLoading()"
              >
          </ng-select>

              <!-- Description  -->
            <div >
              <label for="description">Description</label>
              <textarea id="description" rows="5" placeholder="enter event details..." formControlName="Description" class="form-control"></textarea>
            </div>

        </div>

        <!-- Upload Cover Image  -->
        <div class="col-12 col-lg-5 mt-4">
          <div *ngIf="coverImageUrl" class="mt-2">
            <img [src]="coverImageUrl"  *ngIf="!ImageUploadError"  alt="Cover Image Preview" class="img-fluid rounded shadow-sm" [ngClass]="{'img-mask': ImageUploading}" />
          </div>

          <div class="text-center text-success" *ngIf="ImageUploaded"> <i class="bi bi-check-circle"></i> Image uploaded. </div>
          <div class="text-center text-danger" *ngIf="ImageUploadError"> {{ImageUploadErrorMsg}} </div>
          <div *ngIf="ImageUploading" class="image-uploading-div"> <div class="spinner-border text-info" role="status"></div> <br> uploading... </div>

          <div class="col-12" *ngIf="ImageUploadInitial">
            <div class="custom-file-input" >
              <input id="cover-image" type="file" (change)="onCoverImageSelected($event)" accept="image/*" />
              <label for="cover-image" class="custom-file-label"> 
                <div *ngIf="ImageUploadInitial"> <i class="bi bi-cloud-arrow-up"></i> <br> Upload a cover photo </div>            
              </label>
            </div>
          </div>

          <div class="col-12" *ngIf="ImageUploadUpdate">
            <div class="custom-file-input" >
              <input id="cover-image" type="file" (change)="onCoverImageSelected($event)" accept="image/*" />
              <label for="cover-image" class="custom-file-label-update"> 
                <div *ngIf="ImageUploadUpdate"> <i class="bi bi-arrow-clockwise"></i> Change the photo </div>                
              </label>
            </div>
          </div>
        </div>
      </div>


       <!-- Location -->
      <div class="group-title"> Location </div>

       <!-- Map -->
      <div class="row" *ngIf="!createEventForm.get('IsOnlineEvent')?.value">
        <div class="col-12 col-lg-7">
          <div><input #pacInput class="map-search-box" type="text" placeholder="Enter a location or click from map"/></div>
          <div id="map" #mapContainer class="mt-3" style="height: 400px; width: 100%;"></div>
        </div>

        <!-- Address fields -->
        <div class="col-12 col-lg-5 pt-2">

          <!-- Country -->
          <app-input 
            label="Country" 
            formControlName="CountryName" 
            [formGroup]="createEventForm" 
            [isRequired]="true"
            validationMessage="Country is required, please pick an address from the map">
          </app-input>

          <!-- State/Province/Region -->
          <app-input 
            label="StateProvinceRegion" 
            formControlName="StateProvinceRegion" 
            [formGroup]="createEventForm" 
            [isRequired]="true"
            validationMessage="State/Province/Region is required">
        </app-input>

          <!-- City/Town -->
          <app-input 
            label="CityTown" 
            formControlName="CityTown" 
            [formGroup]="createEventForm" 
            [isRequired]="true"
            validationMessage="City/Town is required">
          </app-input>


          <!-- City/Town -->
          <app-input 
            label="StreetBuildingEtc" 
            formControlName="StreetBuildingEtc" 
            [formGroup]="createEventForm" 
            [isRequired]="true"
            validationMessage="Street/Building/Etc is required">
          </app-input>



          <!-- ZIP Code  -->
          <app-input 
            label="PostalZIPCode" 
            formControlName="PostalZIPCode" 
            [formGroup]="createEventForm" 
            [isRequired]="true"
            validationMessage="Postal/ZIPCode is required">
          </app-input>
  
        
          <!-- Place Name  -->
          <app-input 
            label="PlaceName" 
            formControlName="PlaceName" 
            [formGroup]="createEventForm" 
            [isRequired]="true"
            validationMessage="PlaceName is required. Why? let`s help your attendees to find it easily">
          </app-input>

      </div>
    </div>


     <div class="row">
      <div class="col-12 pt-2">
        <label for="IsOnlineEvent" class="form-label" style="cursor: pointer;"> Online Event </label>
        <input id="IsOnlineEvent" type="checkbox" formControlName="IsOnlineEvent" class="form-check-input ms-2" style="cursor: pointer;"/>
      </div>
     </div>

      
      <div class="group-title"> <i class="bi bi-telephone"></i> Contact Links </div>


      <div class="row">
        <div class="col-12 col-lg-6">
          
            <div class="form-group">
              <label for="instagramUrl"><i class="bi bi-instagram"></i> Instagram URL</label>
              <input type="text" id="instagramUrl" formControlName="InstagramUrl" class="form-control" />
            </div>
      
            <div class="form-group">
              <label for="facebookUrl"><i class="bi bi-facebook"></i> Facebook URL</label>
              <input type="text" id="facebookUrl" formControlName="FacebookUrl" class="form-control" />
            </div>

        </div>
        <div class="col-12 col-lg-6">
          
            <div class="form-group">
              <label for="youtubeUrl"> <i class="bi bi-youtube"></i> Youtube URL</label>
              <input type="text" id="youtubeUrl" formControlName="YoutubeUrl" class="form-control" />
            </div>
        
            <div class="form-group">
              <label for="websiteUrl"> <i class="bi bi-browser-chrome"></i> Website URL</label>
              <input type="text" id="websiteUrl" formControlName="WebSiteUrl" class="form-control" />
            </div>

        </div>
      </div>


      <div class="group-title"> <i class="bi bi-calendar-week"></i> Time </div>
      

      <div class="row">
        <div class="col-12 col-lg-6">

            <div class="form-group">
              <label for="starts-at">Starts At</label>
              <input type="datetime-local" id="starts-at" formControlName="StartsAt" class="form-control" 
              [ngClass]="{'is-invalid': createEventForm.get('StartsAt')?.invalid && createEventForm.get('StartsAt')?.touched}"
              />
              <div *ngIf="createEventForm.get('StartsAt')?.invalid && createEventForm.get('StartsAt')?.touched" class="invalid-feedback">
                  Start time is required
              </div>
            </div>

        </div>

        <div class="col-12 col-lg-6">

            <div class="form-group">
              <label for="ends-at">Ends At</label>
              <input type="datetime-local" id="starts-at" formControlName="EndsAt" class="form-control" 
              [ngClass]="{'is-invalid': createEventForm.get('EndsAt')?.invalid && createEventForm.get('EndsAt')?.touched}"
              />
              <div *ngIf="createEventForm.get('EndsAt')?.invalid && createEventForm.get('EndsAt')?.touched" class="invalid-feedback">
                  End time is required
              </div>
            </div>

        </div>
      </div>


     <!-- Ticket Options -->
    <div class="group-title"> <i class="bi bi-ticket-perforated"></i> Ticket Options </div>


      <div *ngIf="!createEventForm.get('IsFreeEvent')?.value">
        
        <table class="table table-striped mt-1">
            <thead>
            <tr>
                <th>Name</th>
                <th>Price</th>
                <th>Description</th>
                <th>Quota</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let ticket of ticketOptions.controls; let i = index">
                <td>{{ ticket.get('Name')?.value }}</td>
                <td>{{ ticket.get('Price')?.value | currency }}</td>
                <td>{{ ticket.get('Description')?.value }}</td>
                <td>{{ ticket.get('Quota')?.value }}</td>
                <td>
                <button type="button" class="btn btn-outline-primary btn-sm" style="margin-right:5px;" (click)="openTicketOptionModal(i)"><i class="bi bi-pencil"></i></button>
                <button type="button" class="btn btn-outline-danger btn-sm" (click)="removeTicketOption(i)"> <i class="bi bi-trash3"></i></button>
                </td>
            </tr>
            </tbody>
        </table>
        <button type="button" class="btn btn-primary" (click)="openTicketOptionModal()"> <i class="bi bi-plus-circle"></i> Add Ticket Option</button>
        <!-- Ticket Option Modal -->
        <div class="modal" tabindex="-1" [ngClass]="{'show': isTicketOptionModalVisible}" [ngStyle]="{'display': isTicketOptionModalVisible ? 'block' : 'none'}">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{currentEditIndex !== null ? 'Edit' : 'Add'}} Ticket Option</h5>
                  <button type="button" class="btn-close" (click)="closeTicketOptionModal()"></button>
                </div>
                <div class="modal-body">
                  <form [formGroup]="ticketOptionForm">
                    <div class="form-group">
                      <label for="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        formControlName="Name"
                        class="form-control"
                        [ngClass]="{'is-invalid': ticketOptionForm.get('Name')?.invalid && ticketOptionForm.get('Name')?.touched}"
                      />
                      <div *ngIf="ticketOptionForm.get('Name')?.invalid && ticketOptionForm.get('Name')?.touched" class="invalid-feedback">
                        Name is required
                      </div>
                    </div>
        
                    <div class="form-group">
                      <label for="price">Price</label>
                      <input
                        type="number"
                        id="price"
                        formControlName="Price"
                        class="form-control"
                        [ngClass]="{'is-invalid': ticketOptionForm.get('Price')?.invalid && ticketOptionForm.get('Price')?.touched}"
                      />
                      <div *ngIf="ticketOptionForm.get('Price')?.invalid && ticketOptionForm.get('Price')?.touched" class="invalid-feedback">
                        Price is required and should be >= 2
                      </div>
                    </div>
        
                    <div class="form-group">
                      <label for="description">Description</label>
                      <textarea id="description" formControlName="Description" placeholder="what it covers? Add details here..." class="form-control"></textarea>
                    </div>
        
                    <div class="form-group">
                      <label for="quota">Quota</label>
                      <input
                        type="number"
                        id="quota"
                        formControlName="Quota"
                        class="form-control"
                        [ngClass]="{'is-invalid': ticketOptionForm.get('Quota')?.invalid && ticketOptionForm.get('Quota')?.touched}"
                      />
                      <div *ngIf="ticketOptionForm.get('Quota')?.invalid && ticketOptionForm.get('Quota')?.touched" class="invalid-feedback">
                        Quota is required and should be >= 0
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" (click)="closeTicketOptionModal()">Cancel</button>
                  <button type="button" class="btn btn-primary" (click)="addOrUpdateTicketOption()">Save</button>
                </div>
              </div>
            </div>
          </div>  
    
    </div>

    <div class="col-md-6 mt-3">
      <label for="is-free-event" class="form-label" style="cursor: pointer;">This is a Free Event</label>
      <input id="is-free-event" type="checkbox" formControlName="IsFreeEvent" class="form-check-input ms-2" style="cursor: pointer;"/>
    </div>
  

      <button type="submit" class="btn btn-primary mt-5"><i class="bi bi-plus-circle"></i> Create Event</button>


      <div *ngIf="hasFormBeenSubmitted && createEventForm.invalid" class="invalid-feedback">
        <i class="bi bi-exclamation-diamond"></i> Please check invalid fields above
      </div>
      
    </form>
  
  </div>