import { Component, OnInit, OnDestroy  } from '@angular/core';
import { Page, EventCardItem } from '../../../../models/event';
import { ViewEventService } from '../../../../services/view-event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cardview',
  templateUrl: './cardview.component.html',
  styleUrl: './cardview.component.css'
})
export class CardviewComponent  implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  isLoading = true;
  errorMessage = '';
  response: Page<EventCardItem> | null = null;

  show:boolean = false; // show or hide the filter panel


  constructor(public viewEventService: ViewEventService) { }

  ngOnInit() 
  {
    this.subscriptions.add(this.viewEventService.filterChanged$.subscribe(_ => {  this.loadEvents();  }));
    this.loadEvents();  // Initial load
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


  loadEvents(pageNo: number = 1) {
    this.isLoading = true;
    this.viewEventService.getEventCards(pageNo)
    .subscribe({
        next: (p) => {
            // console.log("getEventCards Response received!");           
            this.response = p;
            // console.log(this.response);
            this.isLoading = false;
        },
        error: (error) => {
            this.errorMessage = error;
            this.isLoading = false;
        },
    });
}

previous() { 
  this.loadEvents(this.response!.PageNo - 1); 
}

next() { 
  this.loadEvents(this.response!.PageNo + 1); 
}


}
