import { Component } from '@angular/core';
import { AppInfo } from '../../../models/static-data';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.css'
})
export class ContactUsComponent {

  AppInfo = AppInfo;

  constructor() {

   

   }

}
