<div class="col-md-12 table-responsive">
  

<table class="table table-hover table table-hover">
    <thead>
      <tr>
        <th>title</th>
        <th>startTime</th>
        <th>organizer</th>
        <th>country</th>
        <th>province</th>
        <th>ticketPrice</th>
        <th>type</th>
        <th>source</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngIf="show_filter">
        <td></td>
        <td></td>
        <td></td>
        <td>
          <div class="flex-container">                   
            <input type="text" placeholder="country" [(ngModel)]="country_filter"  (keyup.enter)="loadEvents()" />
            <i class="bi bi-search"></i>
          </div>
        </td>
        <td></td>
        <td></td>
        <td>
          <div class="flex-container">                    
            <input type="text" placeholder="type" [(ngModel)]="type_filter" (keyup.enter)="loadEvents()" />
            <i class="bi bi-search"></i>
          </div>
        </td>
        <td>
          <div class="flex-container">                   
            <input type="text" placeholder="source" [(ngModel)]="source_filter" (keyup.enter)="loadEvents()" />
            <i class="bi bi-search"></i>
          </div>
        </td>
      </tr>

      <tr *ngFor="let event of response?.Data">
        <td>{{ event.Title }}</td>
        <td>{{ event.StartTime | date }}</td>
        <td>{{ event.Organizer }}</td>
        <td>{{ event.CountryCode }}</td>
        <td>{{ event.Province }}</td>
        <td>{{ event.TicketPrice }}</td>
        <td>{{ event.Type }}</td>
        <td>{{ event.Source }}</td>
      </tr>
    </tbody>
  </table>


  <div class="text-center" *ngIf="isLoading"><div class="spinner-border text-info" role="status"></div></div>
  <div *ngIf="response?.NrOfRecs == 0 && !isLoading">
    <h3>No events found</h3>
  </div>


<div class="container" *ngIf="(response?.NrOfPages ?? 0) > 1">
  <div class="row">
    <div class="col-6">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item"><a class="page-link" href="#"> Page {{response?.PageNo}} of {{response?.NrOfPages}} </a></li>
        </ul>
      </nav>
    </div>
    <div class="col-6  d-flex flex-row-reverse">
      <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item"><a class="page-link" (click)="previous()">Previous</a></li>
            <li class="page-item"><a class="page-link" (click)="next()">Next</a></li>
          </ul>
      </nav>
    </div>
  </div>
</div>

</div>