import { Component, inject, signal, Inject } from '@angular/core';
import { ViewEventService } from '../../../../services/view-event.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css'
})

export class FilterComponent {

  showFilterPanel = signal(false);

  public viewEventService = inject(ViewEventService);

  toggleFilterPanel(): void {
    this.showFilterPanel.set(!this.showFilterPanel());
  }

  applyFilters(): void {
    console.log("Applying filters...");
    console.log("Country: " + this.viewEventService.country_selected);
    console.log("StateProvinceRegion: " + this.viewEventService.stateProvinceRegion_selected);
    console.log("Categries: " + this.viewEventService.categories_selected);
    this.toggleFilterPanel();
    this.viewEventService.applyFilters();
  }

 
}
