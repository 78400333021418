import { Component } from '@angular/core';

@Component({
  selector: 'app-the-biggest-event-search-engine',
  templateUrl: './the-biggest-event-search-engine.component.html',
  styleUrl: './the-biggest-event-search-engine.component.css'
})
export class TheBiggestEventSearchEngineComponent {

}
