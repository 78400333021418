<div class="text-center" *ngIf="isLoading"><div class="spinner-border text-info" role="status"></div></div>
<div *ngIf="response?.NrOfRecs == 0 && !isLoading">
<h3>No events found</h3>
</div>


<div class="container mb-5">
    <div class="row">
        <div class="container col-12">
            <div class="row" *ngIf="!isLoading">
                <app-card *ngFor="let cardItem of response?.Data" 
                    class="col-xl-3 col-lg-4 col-sm-6 col-12" 
                    [card]="cardItem">
                </app-card>

                <div class="container" *ngIf="(response?.NrOfPages ?? 0) > 1">
                    <div class="row">
                      <div class="col-6">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item"><a class="page-link" href="#"> Page {{response?.PageNo}} of {{response?.NrOfPages}} </a></li>
                          </ul>
                        </nav>
                      </div>
                      <div class="col-6  d-flex flex-row-reverse">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                              <li class="page-item"><a class="page-link" (click)="previous()">Previous</a></li>
                              <li class="page-item"><a class="page-link" (click)="next()">Next</a></li>
                            </ul>
                        </nav>
                      </div>
                    </div>
                  </div>


                <div class="col-12 text-center mt-3 mb-3" *ngIf="!isLoading">
                    <img src="assets/images/show-on-map.png" alt="show on the map"
                        style="border-radius: 10px;
                                border: 1px solid dodgerblue;
                                cursor: pointer;
                                transition: transform 0.3s ease, box-shadow 0.3s ease;
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                width: 80%; /* Adjust width as needed */
                                max-width: 500px; /* Ensures it does not grow too large */
                                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);"
                        onmouseover="this.style.transform='scale(1.05)'; this.style.boxShadow='0 8px 15px rgba(0, 0, 0, 0.3)';"
                        onmouseout="this.style.transform='scale(1)'; this.style.boxShadow='0 4px 6px rgba(0, 0, 0, 0.1)';">
                </div> 
            </div>
        </div>
    </div>
</div>