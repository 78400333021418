import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CreateEvent, EventType, TicketOption } from '../../../models/create-event';
import { OrganizerOpsService } from '../../../services/organizer-ops.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event-old.component.html',
  styleUrls: ['./create-event-old.component.css']
})
export class CreateEventOldComponent implements OnInit {
  createEventForm: FormGroup;
  coverImageFile: File | null = null;
  coverImgURL: string = '';
  eventTypes = Object.values(EventType);
  coverImageUrl: string | ArrayBuffer | null = null;

  constructor(
    private fb: FormBuilder,
    private organizerOpsService: OrganizerOpsService
  ) {
    this.createEventForm = this.fb.group({
      Title: ['', Validators.required],
      Description: [''],
      IsOnlineEvent: [false],
      CountryCode: ['', Validators.required],
      Province: [''],
      City: ['', Validators.required],
      District: [''],
      Address: [''],
      ZipCode: [''],
      InstagramUrl: [''],
      FacebookUrl: [''],
      YoutubeUrl: [''],
      WebSiteUrl: [''],
      Type: ['', Validators.required],
      StartsAt: ['', Validators.required],
      EndsAt: ['', Validators.required],
      IsFreeEvent: [false],
      TicketOptions: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.createEventForm.get('IsFreeEvent')?.valueChanges.subscribe((isFree) => {
      const ticketOptionsArray = this.createEventForm.get('TicketOptions') as FormArray;
      if (isFree) {
        while (ticketOptionsArray.length) {
          ticketOptionsArray.removeAt(0);
        }
      }
    });
  }

  get ticketOptions(): FormArray {
    return this.createEventForm.get('TicketOptions') as FormArray;
  }

  addTicketOption(): void {
    console.log('Adding ticket option...');

    const ticketOptionForm = this.fb.group({
      Name: ['', Validators.required],
      Price: [0, [Validators.required, Validators.min(0)]],
      Quota: [1, [Validators.required, Validators.min(1)]],
      Description: ['']
    });

    this.ticketOptions.push(ticketOptionForm);
    console.log('Ticket option added:', this.ticketOptions.controls);
  }

  removeTicketOption(index: number): void {
    this.ticketOptions.removeAt(index);
    console.log('Ticket option removed:', this.ticketOptions.controls);
  }

  onCoverImageSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.coverImageFile = input.files[0];

      const reader = new FileReader();
      reader.onload = () => { this.coverImageUrl = reader.result; };
      reader.readAsDataURL(this.coverImageFile);
    }
  }

  uploadCoverImage(): void {
    if (!this.coverImageFile) return;

    this.organizerOpsService.getSignedUrlToUploadCoverImg().subscribe(
      (response) => {
        const signedUrl = response.Result;
        console.log('Signed URL:', signedUrl);

        this.organizerOpsService.uploadCoverImageToS3(signedUrl, this.coverImageFile as File).subscribe(
          () => {
            this.coverImgURL = signedUrl.split('?')[0];
            console.log('Cover image uploaded successfully:', this.coverImgURL);
          },
          (error) => {
            console.error('Error uploading cover image:', error);
          }
        );
      },
      (error) => {
        console.error('Error fetching signed URL:', error);
      }
    );
  }

  createEvent(): void {
    this.logValidationErrors();
    
    if (this.createEventForm.valid) {
      const eventData: CreateEvent = {
        Title: this.createEventForm.value.Title,
        Description: this.createEventForm.value.Description,
        CoverImgURL: this.coverImgURL,
        IsOnlineEvent: this.createEventForm.value.IsOnlineEvent,
        CountryCode: this.createEventForm.value.CountryCode,
        Lat:0,
        Lng:0,

        StateProvinceRegion: this.createEventForm.value.Province,
        CityTown: this.createEventForm.value.City,
        StreetBuildingEtc: this.createEventForm.value.District,
        PlaceName: this.createEventForm.value.Address,
        PostalZIPCode: this.createEventForm.value.ZipCode,
        InstagramUrl: this.createEventForm.value.InstagramUrl,
        FacebookUrl: this.createEventForm.value.FacebookUrl,
        YoutubeUrl: this.createEventForm.value.YoutubeUrl,
        WebSiteUrl: this.createEventForm.value.WebSiteUrl,
        Type: this.createEventForm.value.Type,
        StartsAt: new Date(this.createEventForm.value.StartsAt).toISOString(),
        EndsAt: new Date(this.createEventForm.value.EndsAt).toISOString(),
        TicketOptions: this.createEventForm.value.TicketOptions
      };

      this.organizerOpsService.createEvent(eventData).subscribe(
        (response) => {
          console.log('Event created successfully:', response);
        },
        (error) => {
          console.error('Error creating event:', error);
        }
      );
    } else {
      console.error('Form is invalid:', this.createEventForm.errors);
      console.log('Cover image URL:', this.coverImgURL);
    }
  }

  private logValidationErrors(): void {
    for (const controlName in this.createEventForm.controls) {
      const control = this.createEventForm.get(controlName);
      if (control && control.invalid) {
        const errors = control.errors;
        console.error(`${controlName} is invalid:`, errors);
      }
    }
  }
}
