import { Injectable, signal } from '@angular/core';
import { EventWhen } from '../models/static-data';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Page, EventListItem, EventCardItem, EventDetailVM, FilterData, EventMapItem, MapData  } from '../models/event';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})

export class ViewEventService {

  private filterChanged = new BehaviorSubject<boolean>(false);
  // Observable for components to subscribe
  public filterChanged$ = this.filterChanged.asObservable();

  private apiUrl = environment.apiUrl;

  public showFilterPanel= signal<boolean>(true);
  public filterDataLoading = signal<boolean>(true);
  public filterData = signal<FilterData>({ Categories: [], Countries: [], Types: [] });

  // selected values
  public country_selected = signal<string>('');
  public stateProvinceRegion_selected = signal<string>('');
  public categories_selected = signal<string>('');
  public type_selected = signal<string>('');

  // when filter
  public eventWhens = this.getEnumValues(EventWhen);
  public when_selected = signal<string>(''); 
  public whencustom_selected = signal<string>('');

  constructor(private http: HttpClient) 
  {
    this.getFilterData(); // get filter data only once when the service is created
  }

  getEnumValues(enumObj: any): any[] 
  {
    return Object.keys(enumObj)
      .filter(key => isNaN(Number(key)))
      .map(key => ({ name: key, value: enumObj[key] }));
  }

    applyFilters(): void {
      console.log("service: Applying filters...");
      this.filterChanged.next(true);
  }

  getEventList(pageNo: number = 1, country:string, type:string, source:string): Observable<Page<EventListItem>> 
  {
      const url = `${this.apiUrl}app/view-events?view=list&page=${pageNo}&country=${country}&type=${type}&source=${source}`;
      return this.http.get<Page<EventListItem>>(url);
  }

  getEventCards(pageNo: number = 1): Observable<Page<EventCardItem>> 
  {
      const url = `${this.apiUrl}app/view-events?view=card&page=${pageNo}&country=${this.country_selected}&stateprovinceregion=${this.stateProvinceRegion_selected}&categories=${this.categories_selected}&type=${this.type_selected}`;
      return this.http.get<Page<EventCardItem>>(url);
  }


  getMapMarks(latMin: number, latMax: number, lngMin: number, lngMax: number): Observable<MapData<EventMapItem>> 
  {
    const url = `${this.apiUrl}app/view-events?view=map&searhmode=map-area&country=${this.country_selected}&stateprovinceregion=${this.stateProvinceRegion_selected}&categories=${this.categories_selected}&type=${this.type_selected}&latMin=${latMin}&latMax=${latMax}&lngMin=${lngMin}&lngMax=${lngMax}`;
    return this.http.get<MapData<EventMapItem>>(url);
}



  getEventDetails(id: string): Observable<EventDetailVM> 
  {
      const url = `${this.apiUrl}app/view-events?view=eventdetails&eventId=${id}`;
      return this.http.get<EventDetailVM>(url);
  }

  getFilterData() 
  {
    this.filterDataLoading.set(false);
    const url = `${this.apiUrl}app/view-events?view=filterdata`;
    this.http.get<FilterData>(url)
    .subscribe({
      next: (data) => { 
        this.filterData.set(data); 
        console.log('FfilterData:', this.filterData());
        this.filterDataLoading.set(false);
      },
      error: (err) => 
        { 
          console.error('Failed to load filter data:', err); 
          this.filterDataLoading.set(false);
      }
    });
  }

}